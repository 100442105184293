import React from 'react'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'

import BaseForm from '../forms/BaseForm'
import BackendService from '../BackendService';

import _ from 'lodash'
import { createDialogue } from '../actions';
import { connect } from 'react-redux';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {ListGroup} from 'react-bootstrap';

class FormBenutzer extends BaseForm {

    constructor(...args) {
        super(...args);

        this.default = {
            selected: {
                ...this.default.selected,
                "benutzer_id": 0,
                "kontakt_id": 0,
                "benutzername": '',
                "dt_erstellt": null,
                "erstellt_von": 0,
                "vorname": '',
                "nachname": '',
                "kuerzel": '',
                "firma": '',
                "strasse": '',
                "plz": '',
                "ort": '',
                "email": '',
                "telefon": '',
                "mobil": '',
                "fax": '',
                "aktiv": true,
                'passwort': ''
            }
        }
        this.state = {
            ...this.default,
            groups: {
                all: [],
                preEdit: [],
                edited: [],
                selected: {},
            },
            roles: {
                all: [],
                preEdit: [],
                edited: [],
                selected: {},
            },
            namedPermissions: {
                groups: {},
                roles: {}
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleGroupAndRoleSubmit = this.handleGroupAndRoleSubmit.bind(this);
        this.checkUsernameDuplicate = this.checkUsernameDuplicate.bind(this);

        BackendService.getGroups().then((groups) => {
            groups.map(group => {
                group.id = group.gruppe_id
                group.key = group.id
                return group
            });

            let namedPermGroups = {}
            for(let i in groups) {
                const group = groups[i]
                namedPermGroups[group.id] = []

                group.gruppen_rollen.map(role => {
                    role.rollen_rechte.map(recht => {
                        if(_.indexOf(namedPermGroups[group.id], recht.bez) === -1){
                            namedPermGroups[group.id].push(recht.bez)
                        }
                    })
                })

                group.gruppen_rechte.map(recht => {
                    if(_.indexOf(namedPermGroups[group.id], recht.bez) === -1){
                        namedPermGroups[group.id].push(recht.bez)
                    }
                })

                namedPermGroups[group.id].sort()
            }

            this.setState({
                groups: {
                    ...this.state.groups,
                    all: groups
                },
                namedPermissions: {
                    groups: namedPermGroups,
                    roles: this.state.namedPermissions.roles
                }
            })

        })

        BackendService.getRoles().then((roles) => {
            roles.map(role => {
                role.id = role.rolle_id
                role.key = role.id
                return role
            });

            let namedPerm = {}
            for(let i in roles) {
                const role = roles[i]
                namedPerm[role.id] = []
                role.rollen_rechte.map(recht => {
                    if(_.indexOf(namedPerm[role.id], recht.bez) === -1){
                        namedPerm[role.id].push(recht.bez)
                    }
                namedPerm[role.id].sort()
                })
            }



            this.setState({
                roles: {
                    ...this.state.roles,
                    all: roles
                },
                namedPermissions: {
                    groups: this.state.namedPermissions.groups,
                    roles: namedPerm
                }
            })
        })
    }

    reset() {
        super.reset();
        let groups = []
        if (this.props.selected.gruppen !== undefined) {
            groups = this.props.selected.gruppen
            groups.map(group => {
                group.id = group.gruppe_id
                group.key = group.id
                return group
            });
        }
        let roles = []
        if (this.props.selected.rollen !== undefined) {
            roles = this.props.selected.rollen
            roles.map(role => {
                role.id = role.rolle_id
                role.key = role.id
                return role
            });
        }
        this.setState({
            groups: {
                ...this.state.groups,
                preEdit: groups,
                edited: groups
            },
            roles: {
                ...this.state.roles,
                preEdit: this.props.selected.rollen,
                edited: this.props.selected.rollen
            },
            namedPermissions: {
                groups: this.state.namedPermissions.groups,
                roles: this.state.namedPermissions.roles,
            }
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.checkUsernameDuplicate()) {
            return
        }
        if (this.props.new) {
            BackendService.createUser(this.state.selected).then(response => {
                this.handleGroupAndRoleSubmit(this.state.groups.edited, this.state.roles.edited, response).then((response => {
                    this.props.onSubmit(response)
                }))
            })
        } else {
            let data = _.cloneDeep(this.state.selected)
            data.passwort = undefined;
            BackendService.editUser(this.state.selected).then(response => {
                this.handleGroupAndRoleSubmit(this.state.groups.edited, this.state.roles.edited, response).then((response => {
                    this.props.onSubmit(response)
                }))
            })
        }
    }

    handleGroupAndRoleSubmit(groups, roles, user) {
        // let groupAdd = _.pullAllWith(_.cloneDeep(groups.edited), groups.preEdit, (a, b) => a.id === b.id)
        // let groupRemove = _.pullAllWith(_.cloneDeep(groups.preEdit), groups.edited, (a, b) => a.id === b.id)
        // let roleAdd = _.pullAllWith(_.cloneDeep(roles.edited), roles.preEdit, (a, b) => a.id === b.id)
        // let roleRemove = _.pullAllWith(_.cloneDeep(roles.preEdit), roles.edited, (a, b) => a.id === b.id)

        let gruppen = groups.map((g) => g.id)
        let rollen = roles.map((r) => r.id)

        return new Promise((resolve, reject) => {
            BackendService.setGroups({ benutzer_id: user.benutzer_id, gruppen: gruppen }).then((data) => {
                BackendService.setRoles({ benutzer_id: user.benutzer_id, rollen: rollen }).then((data) => resolve(data))
            })
        })
    }

    handleDelete() {
        let id = this.state.selected.benutzer_id

        let deleteAction = (response => {
            if (response === true) {
                BackendService.deleteUser(id).then(() => {
                    this.props.onDelete(id)
                })
            }
        })

        this.props.dispatch(createDialogue('benutzer',
            {
                title: 'Löschen',
                text: 'Diese Aktion kann nicht rückgängig gemacht werden.',
                buttons: [
                    {
                        text: 'Löschen',
                        variant: 'primary',
                        value: true
                    },
                    {
                        text: 'Abbrechen',
                        value: false,
                        cancel: true
                    }
                ],
                callback: deleteAction
            },
            'cacel',
        ))
    }

    onPasswordChange(event) {
        event.preventDefault()
        BackendService.changePasswordOfUser(
            {
                benutzer_id: this.state.selected.benutzer_id,
                new_password: this.state.newPassword,
                new_password_confirmation: this.state.newPassword2,
            }).then(
                (response) => {
                })
        this.setState({ showChangePassword: false })

    }

    createPermissionList = (key, container) => {

        return(
            <ListGroup>
                { 
                    this.state.namedPermissions[key] !== undefined && this.state.namedPermissions[key][container.id].map( permissionNamed => {
                        return <ListGroup.Item>{permissionNamed}</ListGroup.Item>
                    }) 
                }
            </ListGroup>
        )
    }

    renderRightsPanel(key, title) {
        let assigned = _.cloneDeep(this.state[key].edited)
        assigned = assigned === undefined ? [] : assigned
        let available = _.cloneDeep(this.state[key].all)
        _.pullAllWith(available, assigned, (a, b) => +a.id === +b.id)

        let availableItems = available.map((container) =>
            <OverlayTrigger
                    placement="right-end"
                    trigger={"hover"}
                    overlay={
                        <Popover>
                            <Popover.Title>
                                {title === 'Gruppen' ? 'Mitglieder dieser Gruppe dürfen':'Der Benutzer darf zusätzlich'}
                            </Popover.Title>
                            <Popover.Content>
                                { this.createPermissionList(key, container) }
                            </Popover.Content>
                        </Popover>
                    }
                >
                <tr style={container.id === this.state[key].selected.id ? { backgroundColor: '#7EABD5' } : {}}
                    onClick={(e) => {
                        this.setState({
                            [key]: {
                                ...this.state[key],
                                selected: container
                            }
                        })
                    }}>
                    <td>{container.bez}</td>
                </tr>
            </OverlayTrigger>
        )

        let assignedItems = assigned.map((container) =>
            <tr style={container.id === this.state[key].selected.id ? { backgroundColor: '#7EABD5' } : {}}
                onClick={(e) => {
                    this.setState({
                        [key]: {
                            ...this.state[key],
                            selected: container
                        }
                    })
                }}>
                <td>{container.bez}</td>
            </tr>
        )

        return (
            <Form.Row>
                <Col xs={5} >
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>
                                {(() => {
                                    switch (title) {
                                        case "Gruppen":   return "verfügbare Gruppen";
                                        case "Sonderberechtigungen":   return "zusätzlich verfügbare Rechte:";
                                    }
                                })()}
                            </th>   
                        </tr>
                        </thead>
                        <tbody>
                            {availableItems}
                        </tbody>
                    </Table>
                    <div style={{ marginBottom: '10px' }}>
                    {(() => {
                        switch (title) {
                            case "Sonderberechtigungen":   return "Administratorenrechte dürfen nur in Absprache mit MP (und in Vertretung KS) vergeben werden";
                        }
                    })()}
                    </div> 
                </Col>

                <Col xs={2} style={{ alignSelf: 'center' }}>
                    <Row style={{ justifyContent: 'center' }}>
                        <Button disabled={this.state[key].selected === undefined || this.state[key].selected.id === undefined}
                            onClick={(e) => {
                                assigned.push(this.state[key].selected)
                                this.setState({
                                    [key]: {
                                        ...this.state[key],
                                        edited: assigned
                                    }
                                })
                            }}
                        >
                            →
                        </Button>
                    </Row>
                    <Row style={{ justifyContent: 'center', marginTop: '5px' }}>
                        <Button disabled={this.state[key].selected === undefined || this.state[key].selected.id === undefined}
                            onClick={(e) => {
                                _.remove(assigned, this.state[key].selected)
                                this.setState({
                                    [key]: {
                                        ...this.state[key],
                                        edited: assigned
                                    }
                                })
                            }}
                        >
                            ←
                        </Button>
                    </Row>
                </Col>

                <Col xs={5} >
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>
                                    {(() => {
                                        switch (title) {
                                            case "Gruppen":   return "Benutzer ist in folgenden Gruppen";
                                            case "Sonderberechtigungen":   return "Benutzer hat zusätzlich Rechte eines:";
                                        }
                                    })()}
                                </th>
                            </tr>
                        </thead>
                        <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                            <tbody>
                                {assignedItems}
                            </tbody>
                        </div>
                    </Table>
                </Col>
            </Form.Row>
        )
    }

    checkUsernameDuplicate() {
        let name = this.state.selected.benutzername
        if (this.props.userExists !== undefined) {
            return this.props.userExists(name)
        } else {
            return false
        }
    }

    render() {
        super.render()
        let disabled = this.state.selected === undefined || this.state.selected.benutzer_id === 0
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Row>
                    <Col>
                        <Form.Group controlId='vorname'>
                            <Form.Label>Vorname:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.vorname} onChange={this.handleChange} required />
                        </Form.Group>
                        <Form.Group controlId='kuerzel'>
                            <Form.Label>Kürzel:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.kuerzel} onChange={this.handleChange} required />
                        </Form.Group>
                        <Form.Group controlId='firma'>
                            <Form.Label>Firma:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.firma} onChange={this.handleChange} required />
                        </Form.Group>
                        <Form.Group controlId='strasse'>
                            <Form.Label>Straße:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.strasse} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='plz'>
                            <Form.Label>PLZ:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.plz} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='ort'>
                            <Form.Label>Ort:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.ort} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='www'>
                            <Form.Label>www:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.www} onChange={this.handleChange} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='nachname'>
                            <Form.Label>Nachname</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.nachname} onChange={this.handleChange} required />
                        </Form.Group>
                        <Form.Group controlId='benutzername'>
                            <Form.Label>Benutzername</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.benutzername} onChange={this.handleChange} required isInvalid={this.checkUsernameDuplicate()} disabled={!this.props.new} />
                            <Form.Control.Feedback type='invalid'>
                                Nutzer existiert bereits
                            </Form.Control.Feedback>
                        </Form.Group>
                        {this.props.new && <Form.Group controlId='passwort'>
                            <Form.Label>Passwort:</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.passwort} onChange={this.handleChange} required />
                            <Form.Control.Feedback type='invalid'>
                                Passwörter dürfen nicht leer sein!
                            </Form.Control.Feedback>
                        </Form.Group>}
                        {!this.props.new && <Form.Group controlId='changePW'>
                            <Col>
                                <Form.Row>
                                    <Form.Label>Passwort:</Form.Label>
                                </Form.Row>
                                <Form.Row>
                                    <Button onClick={() => this.setState({ showChangePassword: true })} disabled={disabled}>Passwort ändern</Button>
                                </Form.Row>
                            </Col>
                        </Form.Group>}
                        <Form.Group controlId='telefon'>
                            <Form.Label>Telefon</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.telefon} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='fax'>
                            <Form.Label>Fax</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.fax} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='mobil'>
                            <Form.Label>Mobil</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.mobil} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId='email'>
                            <Form.Label>E-Mail</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.selected.email} onChange={this.handleChange} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                {this.renderRightsPanel('groups', "Gruppen")}
                {this.renderRightsPanel('roles', "Sonderberechtigungen")}
                <Form.Row>
                    {!this.props.new && <Col xs='auto'>
                        <Button onClick={this.handleDelete}>Löschen</Button>
                    </Col>}
                    <Col xs='auto'>
                        <Button type='submit'>{this.props.new ? 'Anlegen' : 'Speichern'}</Button>
                    </Col>
                </Form.Row>

                <Modal
                    show={this.state.showChangePassword}
                    centered
                    onHide={() => this.setState({ showChangePassword: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Neues Passwort
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{ flexGrow: 1 }}>
                            <Col>
                                <Form onSubmit={(e) => this.onPasswordChange(e)}>
                                    <Form.Group controlId='password' style={{ flexGrow: 1 }}>
                                        <Form.Control type="password" placeholder="Passwort" onChange={e => this.setState({ newPassword: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group controlId='password2' style={{ flexGrow: 1 }}>
                                        <Form.Control type="password" placeholder="Passwort wiederholen"
                                            onChange={e => this.setState({ newPassword2: e.target.value })}
                                            isInvalid={this.state.newPassword2 !== undefined && this.state.newPassword2 !== this.state.newPassword} />
                                        <Form.Control.Feedback type="invalid">
                                            Die Passwörter stimmen nicht überein.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button type='submit' disabled={this.state.newPassword !== undefined && this.state.newPassword !== '' && this.state.newPassword2 !== this.state.newPassword}>Passwort ändern</Button>
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Form>
        )
    }
}

export default connect()(FormBenutzer)
