import { RESOLVE_DIALOGUE, CREATE_DIALOGUE } from '../actions/actionTypes'

const initialState = {

}

const vaView = (state = initialState, action) => {
    switch (action.type) {
        case RESOLVE_DIALOGUE:
            if (state[action.key].callback!==undefined) {
                state[action.key].callback(action.value);
            }
            return Object.assign({}, state, {
                [action.key]: {
                    resolved: action.value
                }
            })
        case CREATE_DIALOGUE:

            return Object.assign({}, state, {
                [action.key]: {
                    ...action.value
                }
            })
        default:
            return state
    }
}

export default vaView