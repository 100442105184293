import { SET_USERDATA, LOGOUT } from '../actions/actionTypes'
import _ from 'lodash';

const processUser = (user) => {

    let roles = []
    let groups = []
    let rights = []

    if (user !== null) {
        groups = user.gruppen
        roles = user.rollen
        roles.forEach(role => {
            rights = _.concat(rights, role.rollen_rechte)
        });

        groups.forEach(group => {
            rights = _.concat(rights, group.gruppen_rechte)

            group.gruppen_rollen.forEach(role => {
                rights = _.concat(rights, role.rollen_rechte)
            });
        });
        rights = _.concat(rights, user.rechte)

        roles = _.keyBy(roles, (role) => role.rolle_id)
        groups = _.keyBy(groups, (group) => group.gruppe_id)
        rights = _.keyBy(rights, (right) => right.recht_id)
    }

    return (
        {
            roles,
            groups,
            rights
        }
    )
}

const initialState = {
    user: JSON.parse(sessionStorage.getItem('user')),
    ...processUser(JSON.parse(sessionStorage.getItem('user')))
}


const userData = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERDATA:
            let user = action.value.data.user
            let data = processUser(user)

            return Object.assign({}, state, {
                ...data,
                user: user
            })
        case LOGOUT:
            sessionStorage.clear();
            return Object.assign({}, state, {
                user: undefined
            })
        default:
            return state
    }
}

export default userData
