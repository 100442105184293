import React from 'react';
import { Provider } from 'react-redux'
import configureStore, { history }from './app/store/index'
import AuthGate from './app/AuthGate.js'
import { Route, Switch } from 'react-router' // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLongArrowAltDown, faLongArrowAltUp, faCaretDown, faCaretUp, faCaretRight, faTasks, faEnvelopeOpenText, faLightbulb, faQuestionCircle, faPrint, faExclamation, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'


import './App.css';

library.add(faLongArrowAltDown, faLongArrowAltUp, faCaretDown, faCaretUp, faCaretRight, faTasks, faClock, faEnvelopeOpenText, faLightbulb, faQuestionCircle, faPrint, faExclamation, faEdit, faTrash)

function App() {
  const store = configureStore(/* provide initial state if any */)
  return (

    <Provider store={store}>
      <ConnectedRouter history={history}> { /* place ConnectedRouter under Provider */}
        <> { /* your usual react-router v4/v5 routing */}
          <Switch>
            <Route render={() => (<AuthGate />)} />
          </Switch>
        </>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
