import React from 'react';
import Form from 'react-bootstrap/Form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import Button from 'react-bootstrap/Button';
import BackendService from '../../BackendService';
import Base64Converter from '../../Base64Converter';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal'

import BaseForm from '../../forms/BaseForm';
import _ from 'lodash';
import {createDialogue, createNotification} from '../../actions';
import {connect} from 'react-redux';
import { isEqual } from 'lodash';

import Can from '../../config/Can'

class FormWerbemittel extends BaseForm {

    constructor(...args) {
        super(...args);

        this.default = {
            selected: {
                ...this.default.selected,
                "artikelnummer": '',
                "beschreibunglang": '',
                "lagerort": '',
                "lieferant": '',
                "preis": '',
                "angeschafftdatum": '',
                "veranstaltungid": this.props.va,
                "bemerkung": '',
                stueckzahl: '',
                artikel: '',
                ausfuerung: '',
                groesse: '',
                logo: '',
                owner: '',
                beschaffer: '',
                angelegt_von: '',
                bearbeitet_von: '',
            },
            images: [],
            newImage: '',
            showImageModal: false,
            imageModalImage: {},
        };

        this.state = this.default;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onEditImage = this.onEditImage.bind(this);
        this.handleImageUpload = this.handleImageUpload.bind(this);

        this.leftCol = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if(!isEqual(prevProps.selected,this.props.selected)){
            this.reset();
        }  
    }

    handleSubmit(event) {
        event.preventDefault();
        let data =
        {
            ...this.state.selected,
            stueckzahl: this.state.selected.stueckzahl === '' ? null : this.state.selected.stueckzahl,
            preis: this.state.selected.preis === '' ? null : this.state.selected.preis
        };
        BackendService.editWerbemittel(data).then((wm) => {
            if (this.props.handleEdit !== undefined) {
                this.props.handleEdit(wm);
            }
            this.props.dispatch(createNotification('Werbemittel', 'Eintrag bearbeitet'))
        });
    }

    handleDelete() {
        let id = this.state.selected.id;

        let deleteAction = (response => {
            if (response === true) {
                BackendService.deleteWerbemittel({id}).then(() => {
                    this.setState(
                        {
                            ...this.default
                        }
                    );
                    if (this.props.handleDelete !== undefined) {
                        this.props.handleDelete(id);
                    }
                    this.props.dispatch(createNotification('Werbemittel', 'Löschen erfolgreich'))
                }).catch(err => {
                    console.log(err);
                });
            }
        })
        this.props.dispatch(createDialogue('wmittel',
            {
                title: 'Löschen',
                text: 'diese Aktion kann nicht rückgängig gemacht werden.',
                buttons: [
                    {
                        text: 'Löschen',
                        variant: 'primary',
                        value: true
                    },
                    {
                        text: 'Abbrechen',
                        value: false,
                        cancel: true
                    }
                ],
                callback: deleteAction
            },
            'cacel',
        ))
    }

    deleteImage(previewId){
        BackendService.deleteWerbemittelFile(previewId).then(() => {
            this.props.refresh();
            if(this.state.showImageModal === true){
                this.setState({showImageModal: false})
            };
        }).catch(err => {
            console.log(err);
        });
    }

    onEditImage(e) {
        let file = e.target.files[0];
        Base64Converter.toBase64(file).then((base64) => {
            this.setState({
                newImage: base64
            });
        });
    }

    handleImageUpload() {
        let base64 = this.state.newImage.replace(/^data:.+;base64,/, '');
        let data = {
            "name": 'werbemittel_' + this.state.selected.id + '_preview',
            "content": base64
        };
        BackendService.createWerbemittelFile(this.state.selected.id, data).then(() => {
            this.props.refresh();
        }).catch(err => {
            console.log(err);
        });
    
        this.setState({newImage: ''});
    }

    reset() {
        super.reset();
        this.setState({
            images: [],
            newImage: ''
        });
        if (this.props.selected.previews !== undefined) {
            this.props.selected.previews.forEach(preview => {
                BackendService.getWerbemittelFile(preview.id).then((data) => {              
                        let images = _.cloneDeep(this.state.images);
                        images.push({preview, data});
                        this.setState({
                            images
                        });
                }).catch(err => {
                    console.log(err);
                });
            });
        }
    }

    renderImages() {
        let images = [];
        if (this.state.newImage === '') {
            this.state.images.forEach(image => {
                images.push(
                    <div key={image.index} style={{backgroundColor: "fff", marginRight:"10px", border: "1px solid #495057"}}>
                        <div style={{width: "180px", height: '180px', padding: '5px', backgroundImage: `url(${image.data})`, backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundSize:"cover" }}>

                        </div>
                        <div style={{width:"100%", backgroundColor:"#002d59", height: "40px", display: "flex", flexFlow:"row nowrap", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                            <FontAwesomeIcon icon="edit" size="lg" style={{color:"#fff", height:"90%", width:"auto", padding: "5% 10px"}} 
                                onClick={() => {
                                this.setState({
                                    showImageModal: true,
                                    imageModalImage: image
                                })
                            }}/>
                            <FontAwesomeIcon icon="trash" size="lg" style={{color:"#fff", height:"90%", width:"auto", padding: "5% 10px"}}
                                onClick={() => this.deleteImage(image.preview.id)}/>
                        </div>
                    </div>
                )
            });

        //shows preview of selected image before upload
        } else {
            images.push(
                <img key={this.state.images.length} src={this.state.newImage} alt=""
                    style={{maxWidth: "100%", maxHeight: '100%', flex: 0, padding: '5px'}} />
            )
        }

        return images
    }

    render() {
        super.render();

        return (
            <Form onSubmit={e => this.handleSubmit(e)}>
                <Form.Row>
                    <Col>
                        <p
                            style={{
                                border: "rgb(204, 204, 204) solid 1px",
                                padding: "10px 20px",
                            }}
                        >
                        Gilt für alle Artikel in STEVE:
                        <br />
                        Artikelkurzbeschreibung (Artikelbezeichnung/Oberbegriff; ggf. Einsatz/Zweck/Ausführung);<br /><br />
                        ergänzende Artikelbeschreibung (ggf. Markenhersteller; ggf. Grundfarbe; ggf. Material; ggf. Haltbarkeitsdatum; ggf. Modell/Ausführung)
                        </p>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group controlId='artikel'>
                            <Form.Label>Artikelkurzbeschreibung:</Form.Label>
                            <Form.Control type="text" value={this.state.selected.artikel} onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                        <Form.Group controlId='ausfuerung'>
                            <Form.Label>Ergänzende Artikelbeschreibung:</Form.Label>
                            <Form.Control type="text" value={this.state.selected.ausfuerung} onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                        <Form.Row>
                            <Col>
                                <Form.Group controlId='groesse'>
                                    <Form.Label>Größe/Gewicht/Inhalt:</Form.Label>
                                    <Form.Control type="text" value={this.state.selected.groesse} onChange={this.handleChange} disabled={this.props.disabled} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId='logo'>
                                    <Form.Label>Logo:</Form.Label>
                                    <Form.Control as="select" value={this.state.selected.logo} onChange={this.handleChange} disabled={this.props.disabled}>
                                        <option key='ohne' value='ohne'>ohne</option>
                                        <option key='SC 2001' value='SC 2001'>SC 2001</option>
                                        <option key='SC 2005' value='SC 2005'>SC 2005</option>
                                        <option key='SC 2017' value='SC 2017'>SC 2017</option>
                                        <option key="SC 2020" value="SC 2020">SC 2020</option>
                                        <option key='Buderus 2005' value='Buderus 2005'>Buderus 2005</option>
                                        <option key='Buderus 2017' value='Buderus 2017'>Buderus 2017</option>
                                        <option key="STAR" value="STAR">STAR</option>
                                        <option key="Veranstaltungslogo" value="Veranstaltungslogo">Veranstaltungslogo</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        {/* <Form.Group controlId='beschreibunglang'>
                            <Form.Label>Beschreibung (lang)(legacy):</Form.Label>
                            <Form.Control as="textarea" value={this.state.selected.beschreibunglang} rows={3} disabled={true} readOnly />
                        </Form.Group> */}
                        <Form.Row>
                            <Col>
                                <Form.Group controlId='owner'>
                                    <Form.Label>Artikel-Owner:</Form.Label>
                                    <Form.Control type="text" value={this.state.selected.owner} onChange={this.handleChange} placeholder='z.B. TTDB/SBC2' disabled={this.props.disabled} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId='beschaffer'>
                                    <Form.Label>Beschaffer:</Form.Label>
                                    <Form.Control type="text" value={this.state.selected.beschaffer} onChange={this.handleChange} placeholder='z.B. KS' disabled={this.props.disabled} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId='angelegt_von'>
                                    <Form.Label>hier abgelegt von: </Form.Label>
                                    <Form.Control type="text" value={this.state.selected.angelegt_von} disabled={true} readOnly />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId='bearbeitet_von'>
                                    <Form.Label>zuletzt bearbeitet von: </Form.Label>
                                    <Form.Control type="text" value={this.state.selected.bearbeitet_von} disabled={true} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Group controlId='bemerkung'>
                            <Form.Label>Bemerkung/Verwendung:</Form.Label>
                            <Form.Control as="textarea" value={this.state.selected.bemerkung} onChange={this.handleChange} rows={2} placeholder='Notwendige und ergänzende Bemerkung zum Artikel. Wenn CWS-Artikel: Identische Artikelbezeichnung wie im CWS hier eingetragen.' disabled={this.props.disabled} />
                        </Form.Group>
                        {this.props.bestand &&
                            <Form.Group controlId='lieferant'>
                                <Form.Label>Lieferant:</Form.Label>
                                <Form.Control type="text" value={this.state.selected.lieferant} onChange={this.handleChange} placeholder='z.B. Dommer Fahnenfabrik' disabled={this.props.disabled} />
                            </Form.Group>}

                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col ref={this.leftCol}>
                        <Form.Group controlId='lagerort'>
                            <Form.Label>Lagerort:</Form.Label>
                            <Form.Control type="text" value={this.state.selected.lagerort} onChange={this.handleChange} placeholder='z.B. Keller SBC2, Keller regional, CWS, privat daheim' disabled={this.props.disabled} />
                        </Form.Group>
                        <Form.Group controlId='artikelnummer'>
                            <Form.Label>Artikelnummer (im TT CWS):</Form.Label>
                            <Form.Control type="text" value={this.state.selected.artikelnummer} onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='preis'>
                            <Form.Label>Preis (€):</Form.Label>
                            <Form.Control type="number" value={this.state.selected.preis} step="0.01" min="0" onChange={this.handleChange} disabled={this.props.disabled} />
                        </Form.Group>
                        {!this.props.bestand &&
                            <Form.Group controlId='stueckzahl'>
                                <Form.Label>Stückzahl:</Form.Label>
                                <Form.Control type="number" value={this.state.selected.stueckzahl} step="1" min="0" onChange={this.handleChange} disabled={this.props.disabled} />
                            </Form.Group>}
                        {this.props.bestand &&
                            <Form.Group controlId='angeschafftdatum'>
                                <Form.Label>Angeschafft am:</Form.Label>
                                <Form.Control type="text" value={this.state.selected.angeschafftdatum} onChange={this.handleChange} placeholder='z.B. 2017' maxLength={4} disabled={this.props.disabled} />
                            </Form.Group>}
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Form.Label>Bilder: (Nur Bilder im Format quer 4x3 (BxH) sowie hoch 3x4 (BxH) hochladen!)</Form.Label>
                    <div style={{display: 'flex', width: '100%', outlineStyle: 'solid', outlineWidth: '1px', outlineColor: '#cccccc', overflowX: 'auto'}}>
                        {this.renderImages()}
                    </div>
                    <Can I="manage" a="VA.Werbemittel">
                        {this.state.newImage === '' ?
                            <Form.Control key='selectImage' type="file" accept="image/png, image/jpeg" onChange={this.onEditImage} disabled={this.props.disabled} />
                            :
                            <Row>
                                <Col>
                                    <Button onClick={(e) => {this.setState({newImage: ''})}}>Abbrechen</Button>
                                </Col>
                                <Col>
                                    <Button onClick={this.handleImageUpload}>Hochladen</Button>
                                </Col>
                            </Row>
                        }
                    </Can>
                </Form.Row>


                <Modal
                    show={this.state.showImageModal}
                    size="lg"
                    centered
                    onHide={() => this.setState({showImageModal: false})}
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col style={{display: 'flex', justifyContent: 'center'}}>
                                <img src={this.state.imageModalImage.data} alt=""
                                    style={{display: 'flex', justifySelf: 'center', maxWidth: "100%", maxHeight: '100%', flex: 0}} />
                            </Col>
                        </Row>
                        <Row style={{display: 'flex', justifyContent: 'space-around'}}>
                            <Col xs='auto'>
                                <Button onClick={() => this.deleteImage(this.state.imageModalImage.preview.id)}>
                                    Löschen</Button>
                            </Col>
                            <Col xs='auto'>
                                <Button onClick={() =>
                                    BackendService.editWerbemittelFile(this.state.imageModalImage.preview.id, {mainImage: true}).then(() => {
                                        this.props.refresh();
                                        this.setState({showImageModal: false});
                                    }).catch(err => {console.log(err)})}>
                                    In der Liste Verwenden</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Form.Row>
                    <Can I="manage" a="VA.Werbemittel">
                        <Col>
                            {!this.props.selectionOnly &&
                                <Button disabled={this.props.disabled} onClick={this.handleDelete}>Löschen</Button>
                            }
                        </Col>
                        <Col>
                            {!this.props.selectionOnly &&
                                <Button disabled={this.props.disabled} type="submit">Speichern</Button>
                            }
                            {this.props.selectionOnly &&
                                <Button onClick={() => this.props.onCopy()}>in Packliste kopieren</Button>
                            }
                        </Col>
                    </Can>
                </Form.Row>
            </Form>
        )
    }
}

export default connect()(FormWerbemittel)
