import React, { Component } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { connect } from "react-redux";
import { setNavigationRoute } from "../../actions";
import ViewBeteiligte from "./Beteiligte/ViewBeteiligte";
import ChecklistViewDuring from "./Checklisten/ChecklistViewDuring";
import ChecklistViewPrePost from "./Checklisten/ChecklistViewPrePost";
import ViewWerbemittel from "./Werbemittel/ViewWerbemittel";
import ViewAusruestung from "./Ausruestung/ViewAusruestung";

class EditEventMainView extends Component {
  getRoute(key) {
    let route = this.props.selectedTab;
    for (let objkey in this.props.routes) {
      let newroute = this.props.routes[objkey];
      if (+newroute.id === +key) {
        return newroute;
      }
    }
    return route;
  }

  render() {
    const css = `
    #mainContainer::-webkit-scrollbar {
      display: none;
    }
    
    /* Hide scrollbar for IE and Edge */
    #mainContainer {
      -ms-overflow-style: none;
    }
  `;
    return (
      <div
        id="mainContainer"
        className={this.props.className}
        style={{ height: "75vh", overflowY: "auto" }}
      >
        <style>{css}</style>
        <Tabs
          activeKey={this.props.selectedTab.id}
          onSelect={(key) =>
            this.props.dispatch(
              setNavigationRoute(this.getRoute(key), this.props.va)
            )
          }
        >
          <Tab
            eventKey={this.props.routes.EDIT_BETEILIGTE.id}
            title="Beteiligte"
          >
            <ViewBeteiligte />
          </Tab>
          <Tab eventKey={this.props.routes.EDIT_PRE.id} title="Vorbereitung">
            <ChecklistViewPrePost
              listTyp={1}
              hidden={this.props.selectedTab !== this.props.routes.EDIT_PRE}
            />
          </Tab>
          <Tab eventKey={this.props.routes.EDIT_DURING.id} title="Durchführung">
            <ChecklistViewDuring
              listTyp={3}
              hidden={this.props.selectedTab !== this.props.routes.EDIT_DURING}
            />
          </Tab>
          <Tab eventKey={this.props.routes.EDIT_POST.id} title="Nachbereitung">
            <ChecklistViewPrePost
              listTyp={4}
              hidden={this.props.selectedTab !== this.props.routes.EDIT_POST}
            />
          </Tab>
          <Tab
            eventKey={this.props.routes.EDIT_WERBEMITTEL.id}
            title="Werbemittel/Präsente/Material"
          >
            <ViewWerbemittel />
          </Tab>
          <Tab
            eventKey={this.props.routes.EDIT_AUSRUESTUNG.id}
            title="Betreuerausrüstung"
          >
            <ViewAusruestung />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToPropsSeries = (state) => {
  return {
    routes: state.navigation.routes.VERANSTALTUNGEN.subRoutes,
    selectedTab: state.navigation.selectedTab,
    va: state.vaView.selectedVA,
  };
};

export default connect(mapStateToPropsSeries)(EditEventMainView);
