import {
    SET_NAVIGATION_ROUTE,
    SET_USERDATA,
    LOGOUT,
    SET_VA_SELECTED,
    SET_PRINT_TRIGGER,
    RESOLVE_DIALOGUE,
    CREATE_DIALOGUE,
    CREATE_NOTIFICATION,
    REMOVE_NOTIFICATION,
    DELETE_NOTIFICATION
} from './actionTypes';
import {
    push
} from 'connected-react-router'

export const setNavigationRoute = (value, va = undefined) => {
    return function (dispatch, getState) {
        dispatch({
            type: SET_NAVIGATION_ROUTE,
            value: value
        })
        if (getState().router.location.pathname !== GetUrlByRoute(value, va)) {
            dispatch(push(GetUrlByRoute(value, va)))
        }
    }
}

const GetUrlByRoute = (route, va) => {
    if (route.containsVA === true) {
        return route.regex.replace('\\d+', va)
    } else {
        return route.regex
    }
}

export const setUserData = (value) => ({
    type: SET_USERDATA,
    value: value
})

export const logout = (value) => ({
    type: LOGOUT,
    value: value
})

export const setVaSelected = (value) => ({
    type: SET_VA_SELECTED,
    value: value
})

export const setPrintTrigger = (value) => ({
    type: SET_PRINT_TRIGGER,
    value: value
})

export const resolveDialogue = (key, value) => ({
    type: RESOLVE_DIALOGUE,
    key: key,
    value: value
})

export const createDialogue = (key, value, rejectValue = '') => {
    return function (dispatch, getState, cb) {
        dispatch({
            type: CREATE_DIALOGUE,
            key: key,
            value: value,
        })
    }
}

export const createNotification = (title, message, ms = 3000) => {
    return function (dispatch, getState, cb) {
        let object = {
            title: title,
            message: message,
            id: getState().notification.index,
        }
        dispatch({
            type: CREATE_NOTIFICATION,
            value: object
        })
        setTimeout(() => {
            dispatch(removeNotification(object))
        }, ms)
    }
}

export const removeNotification = (value) => {
    return function (dispatch, getState, cb) {
        dispatch({
            type: REMOVE_NOTIFICATION,
            value: value,
        })
        setTimeout(() => {
            dispatch(deleteNotification(value))
        }, 300)
    }
}

export const deleteNotification = (value) => ({
    type: DELETE_NOTIFICATION,
    value: value,
})

