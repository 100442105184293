import React, { Component } from 'react';

import BackendService from '../../BackendService.js';
import _ from 'lodash';
import { createNotification } from '../../actions/index.js';

class BaseViewWerbemittel extends Component {

    constructor(props) {
        super(props);
        this.refresh();
        this.state = {
            data: [],
            selected: {
                id: 0
            },
            images: {}
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleCopy = this.handleCopy.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.refresh = this.refresh.bind(this);

        this.table = React.createRef();
    }

    refresh() {
        return new Promise((resolve) => {
            BackendService.getWerbemittelByVA(this.props.id).then((data) => {
                const size = 20
                let arrayOfData = [];
                for (let index = 0; index < data.length; index += size) {
                    arrayOfData.push(data.slice(index, index + size));
                }

                arrayOfData.reduce((currentPromise, elements) => {
                    return currentPromise.then(() => {
                        let promises = []
                        for (let index = 0; index < elements.length; index++) {
                            let element = elements[index]
                            element.key = element.id;
                            for (let h = 0; h < element.previews.length; h++) {
                                let preview = element.previews[h];
                                if (preview.mainImage) {
                                    promises.push(BackendService.getWerbemittelFile(preview.id).then((data) => {
                                        let prevImages = _.cloneDeep(this.state.images);
                                        this.setDeepChild(prevImages, [element.key, preview.id], data);
                                        this.setState({
                                            images: prevImages
                                        });
                                    }));
                                    break;
                                }                        
                            }
                        }
                        return Promise.all(promises)
                    })
                }, Promise.resolve()).catch(err => console.log(err));
                
                
                let selected = this.state.selected;
                if (selected.id !== 0) {
                    selected = _.find(data, (item) => item.id === selected.id);
                }
                this.setState({
                    data: data,
                    selected
                });
                resolve();
            });
        });
    }

    setDeepChild(obj, path, value) {
        let ret = obj;
        for (let index = 0; index < path.length - 1; index++) {
            if (ret[path[index]] === undefined) {
                ret[path[index]] = {};
            }
            ret = ret[path[index]];
        }
        ret[path[path.length - 1]] = value;
        return ret;
    }

    handleEdit(wm) {
        wm.key = wm.id;
        let werbemittel = _.cloneDeep(this.state.data);
        let index = _.findIndex(werbemittel, (item) => item.id === wm.id);
        werbemittel[index] = wm;
        this.setState({
            data: werbemittel
        });
        this.scrollToElement(wm.id)
    }

    handleNew() {
        BackendService.createWerbemittel({
            artikel: '',
            beschreibunglang: '', 
            artikelnummer: '', 
            lagerort: '', 
            lieferant: '', 
            bemerkung: '', 
            veranstaltungid: this.props.id, 
            ausfuerung: '', 
            groesse: '', 
            logo: '', 
            owner: '', 
            beschaffer: ''
        }).then((wm) => {
            let werbemittel = _.cloneDeep(this.state.data);
            wm.key=wm.id;
            werbemittel.push(wm);
            this.setState({
                data: werbemittel,
                selected: wm
            });
            this.table.current.setSelectedRowKey(wm.id);
            this.scrollToElement(wm.id)
        });
    }

    scrollToElement(id){
        const element = document.getElementById(id);
        if(element){
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
        }  
    }
      
    handleCopy() {
        let va = this.props.id
        BackendService.copyWerbemittel(this.state.selected.id).then(wm => {
            wm.veranstaltungid = va;
            wm.artikel = wm.artikel + " Kopie";
            BackendService.editWerbemittel(wm).then(() => {
              this.refresh()
              this.props.dispatch(
                createNotification("Werbemittel", "Eintrag kopiert")
              );
              this.table.current.setSelectedRowKey(wm.id)
            }) 
        })
    }

    handleDelete(id) {
        let werbemittel = _.cloneDeep(this.state.data) ;
        _.remove(werbemittel, (item) => item.id === id);
        this.setState({
            data: werbemittel,
            selected: {
                id: 0
            },
        });
        this.table.current.setSelectedRowKey(0);
    }

    render() {
        return;
    }
}

export default BaseViewWerbemittel;