import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import BackendService from '../../../BackendService';

class CommentForm extends Component {

    constructor(...args) {
        super(...args);

        this.state = {
            bemerkung: '',
            disabled: false
        };
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            disabled: true
        })
        BackendService.createBemerkung(this.props.id, this.state).then(()=>{
            this.props.close();
        })
    }

    handleChange = e => {
        let value = e.target.value
        this.setState(({
            bemerkung: value
        }))
    };

    render() {
        return (
            <Container fluid>
                <Form onSubmit={e => this.handleSubmit(e)}>
                    <Form.Row>
                        <Form.Group controlId='' style={{ width: '100%' }}>
                            <Form.Control as="textarea" onChange={this.handleChange} rows={6} disabled={this.state.disabled}/>
                        </Form.Group>
                    </Form.Row>
                    <Button type="submit" disabled={this.state.disabled}>Speichern</Button>
                </Form>
            </Container>
        )
    }
}

export default CommentForm