import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import _ from 'lodash';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class CustomTable extends Component {

    constructor(props) {
        super(props)

        let sortValue = this.props.initialSortKey !== undefined ? (row) => row[this.props.initialSortKey] : (row) => row.id
        let sortDirection = this.props.sortDirection === undefined ? 'asc' : this.props.sortDirection
        let initialSortFilter = (rows) => _.orderBy(rows, sortValue, sortDirection)
        this.state = {
            sortFilter: initialSortFilter,
            sortedCollumn: this.props.initialSortKey,
            sortDirection,
            groupToggles: {}
        }

        this.filterWithSearchValue = this.filterWithSearchValue.bind(this);
    }

    onSelect(row, ref) {
        let callback = this.props.onSelect === undefined ? (row) => { } : this.props.onSelect
        this.setSelectedRowKey(row.key)
        callback(row)
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        })
    }

    onDoubleClick(row) {
        let callback = this.props.onDoubleClick === undefined ? (row) => { } : this.props.onDoubleClick
        callback(row)
    }

    setSelectedRowKey(key) {
        this.setState({
            selectedRowKey: key,
        })
    }

    toggleRow(id) {
        let toggles = _.cloneDeep(this.state.groupToggles)
        toggles[id] = toggles[id] === true ? false : true
        this.setState({
            groupToggles: toggles
        })
    }

    renderTableData(data) {
        if (typeof (data) === 'string') {
            let returnValue = []
            let elements = _.split(data, '\r')

            for (let index = 0; index < elements.length; index++) {
                let element = elements[index];
                returnValue.push(element)
                if (index + 1 < elements.length) {
                    returnValue.push(<br />)
                }
            }
            return returnValue;
        }
        return data
    }

    createRow(row, selectionStyle, indentation, data) {
        indentation = indentation === undefined ? 0 : indentation;
        let bodyContent = []
        let i = 0
        this.props.collumns.forEach(collumn => {
            let content = []
            let contentstyle = {}
            if (i === 1) {
                let space = 25 * indentation;
                //contentstyle = { paddingLeft: space + "px", display: 'flex', alignItems: 'center', border: 'transparent' }
                if (row.subElemnts !== undefined) {
                    let icon = this.state.groupToggles[row.id] === true ? <FontAwesomeIcon icon="caret-down" size='lg' /> : <FontAwesomeIcon icon="caret-right" size='lg' />
                    content.push(<span key='groupToggle' onClick={() => this.toggleRow(row.id)} style={{ padding: "4px" }}>{icon}</span>)
                }
            }
            content.push(collumn.cell === undefined ? this.renderTableData(row[collumn.key]) : collumn.cell(row, data, indentation))
            if(content[0] == undefined || content[0] == '' ){
                bodyContent.push(<td style={contentstyle} key={row.key + '_' + collumn.key}>&nbsp;</td>)
            }else{
                bodyContent.push(<td style={contentstyle} key={row.key + '_' + collumn.key}>{content}</td>)
            }
            i++
        })
        const ref = React.createRef();

        let element =
            [<tr onClick={(e) => this.onSelect(row, ref)}
                onDoubleClick={(e) => this.onDoubleClick(row)}
                key={row.key}
                ref={ref}
                id={row.key}
                style={row.key === this.state.selectedRowKey ? selectionStyle : {}}>
                {bodyContent}
            </tr>]

        if (row.subElemnts !== undefined) {
            if (this.state.groupToggles[row.id] === true) {
                row.subElemnts.forEach(subElement => {
                    element.push.apply(element, this.createRow(subElement, selectionStyle, indentation + 1))
                });
            }
        }
        return element;
    }

    filterWithSearchValue(data) {
        let searchValue = this.state.searchValue
        if (searchValue === '') {
            return data
        }
        let regex = RegExp(searchValue, 'i')
        let filteredData = []

        for (let i = 0; i < data.length; i++) {
            let row = data[i]
            for (let h = 0; h < this.props.collumns.length; h++) {
                let collumn = this.props.collumns[h]
                let entry = row[collumn.key]
                if ((typeof entry) == 'string' && regex.test(entry)) {
                    filteredData.push(row)
                    break;
                }
            }
        }

        return filteredData
    }

    getSortedIds() {
        let data = this.props.data;
        data = this.state.sortFilter(data);
        let ids = []
        data.map((row) => ids.push(row.key))
        return ids
    }

    render() {
        let selectionStyle = this.props.selectionStyle === undefined ? { backgroundColor: '#7EABD5' } : this.props.selectionStyle

        let data = this.props.data;
        if (!this.props.hideSearch) {
            data = this.filterWithSearchValue(data, this.state.searchValue)
        }
        data = this.state.sortFilter(data); //sort data

        let headContent = []
        this.props.collumns.forEach(collumn => {
            let sortValue = collumn.sortValue === undefined ? (row) => row[collumn.key] : collumn.sortValue

            let onClick = () => {
                let sortFilter = (rows) => _.orderBy(rows, sortValue, this.state.sortDirection)

                this.setState(prevState => ({
                    sortFilter: sortFilter,
                    sortedCollumn: collumn.key,
                    sortDirection: prevState.sortedCollumn === collumn.key && prevState.sortDirection === 'asc' ? 'desc' : 'asc'
                }))
            }
            let icon = this.state.sortedCollumn === collumn.key ?
                this.state.sortDirection === 'asc' ? <FontAwesomeIcon icon="long-arrow-alt-up" /> : <FontAwesomeIcon icon="long-arrow-alt-down" />
                : undefined

            let inlineStyle = {}

            if (collumn.sortable !== false) {
                inlineStyle.color= '#49A9F5'
            }

            headContent.push(
                <th
                    key={collumn.key}
                    className={"sticky"}
                    onClick={collumn.sortable === false ? () => { return } : onClick}
                    style={inlineStyle}
                >{icon}{' ' + collumn.title}
                </th>)
        })

        let head =
            <thead>
                <tr>
                    {headContent}
                </tr>
            </thead>


        let bodyRows = []
        let i = 0
        //let headInterval = this.props.headInterval === undefined ? 10 : this.props.headInterval
        data.forEach(row => {
        //    if (i !== 0 && i % headInterval === 0) {
        //        bodyRows.push(<tr>{headContent}</tr>)
        //    }
        //    i++
            bodyRows.push.apply(bodyRows, this.createRow(row, selectionStyle, 0, data))
        })
        let emptyTds = []
        this.props.collumns.forEach(collumn => {
            emptyTds.push(<td key={collumn.key}>&nbsp;</td>)
        })


        let body =
            <tbody>
                {bodyRows}
                <tr>
                    {emptyTds}
                </tr>
            </tbody>

        return (
            <Container fluid className="customTable">
                {!this.props.hideSearch &&
                <Row style={{ justifyContent: 'flex-end' , borderLeft: "0px", borderTop:"0px"}} className="sticky">
                        <Form.Group controlId="searchValue">
                            <Form.Control
                                type="text"
                                placeholder="Suchen"
                                value={this.state.searchValue}
                                onChange={(e) => this.setState({ searchValue: e.target.value })} />
                        </Form.Group>
                    </Row>}
                <Row>
                    <Table striped bordered hover size='sm'>
                        {head}
                        {body}
                    </Table>
                </Row>
            </Container>
        )
    }
}

export default CustomTable
