export const SET_NAVIGATION_ROUTE = 'SET_NAVIGATION_ROUTE'
export const SET_PRINT_TRIGGER = 'SET_PRINT_TRIGGER'
export const SET_USERDATA = 'SET_USERDATA'
export const LOGOUT = 'LOGOUT'
export const SET_VA_SELECTED = 'SET_VA_SELECTED'
export const RESOLVE_DIALOGUE = 'RESOLVE_DIALOGUE'
export const CREATE_DIALOGUE = 'CREATE_DIALOGUE'
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const CHECK_HOLIDAY = 'CHECK_HOLIDAY'
