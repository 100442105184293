import { SET_NAVIGATION_ROUTE, SET_PRINT_TRIGGER } from '../actions/actionTypes'

const routes = {
    HOME: {
        regex: '/',
        helpname: 'Home:',
        helpID: 1,
    },
    //VERANSTALTUNGEN: 'VERANSTALTUNGEN',
    VERANSTALTUNGEN: {
        regex: '/Veranstaltungen',
        helpname: 'Veranstaltungen:',
        helpID: 3,
        subRoutes: {
            GRUNDDATEN: {
                regex: '/Veranstaltungen/\\d+/Grunddaten',
                name: 'VA_GRUNDDATEN',
                helpID: 3,
                containsVA: true
            },
            EDIT_BETEILIGTE: {
                regex: '/Veranstaltungen/\\d+/Beteiligte',
                id: 0,
                name: 'VA_EDIT_BETEILIGTE',
                helpID: 4,
                print: true,
                containsVA: true
            },
            EDIT_PRE: {
                regex: '/Veranstaltungen/\\d+/Vorbereitung',
                id: 1,
                name: 'VA_EDIT_PRE',
                helpID: 5,
                print: true,
                containsVA: true
            },
            EDIT_DURING: {
                regex: '/Veranstaltungen/\\d+/Durchfuehrung',
                id: 2,
                name: 'VA_EDIT_DURING',
                helpID: 6,
                print: true,
                containsVA: true
            },
            EDIT_POST: {
                regex: '/Veranstaltungen/\\d+/Nachbereitung',
                id: 3,
                name: 'VA_EDIT_POST',
                helpID: 7,
                print: true,
                containsVA: true
            },
            EDIT_WERBEMITTEL: {
                regex: '/Veranstaltungen/\\d+/Werbemittel',
                id: 4,
                name: 'VA_EDIT_WERBEMITTEL',
                helpID: 8,
                print: true,
                containsVA: true
            },
            EDIT_AUSRUESTUNG: {
                regex: '/Veranstaltungen/\\d+/Ausruestung',
                id: 5,
                name: 'VA_EDIT_AUSRUESTUNG',
                helpID: 8,
                print: true,
                containsVA: true
            },
        }
    },
    BENUTZER: {
        regex: '/Benutzer',
        helpname: 'Benutzer:',
        helpID: 9,
    },
    CHECKLISTEN: {
        regex: '/Checklisten',
        helpname: 'Checklisten:',
        helpID: 15,
        subRoutes: {
            M_EDIT_PRE: {
                regex: '/Checklisten/Vorbereitung',
                id: 1,
                name: 'MASTER_EDIT_PRE',
                helpID: 13,
                print: true,
            },
            M_EDIT_DURING: {
                regex: '/Checklisten/Durchfuehrung',
                id: 2,
                name: 'MASTER_EDIT_DURING',
                helpID: 14,
                print: true,
            },
            M_EDIT_POST: {
                regex: '/Checklisten/Nachbereitung',
                id: 3,
                name: 'MASTER_EDIT_POST',
                helpID: 15,
                print: true,
            },
        }
    },
    TODO: {
        regex: '/ToDo',
        helpname: 'TODO:',
        print: true,
        helpID: 2,
    },
    WERBEMITTEL: {
        regex: '/Werbemittel',
        helpname: 'Werbemittel:',
        print: true,
        helpID: 11,
    },
    AUSRUESTUNG: {
        regex: '/Ausruestung',
        helpname: 'Werbemittel',
        print: true,
        helpID: 11,
    },
}

const initialState = {
    routes,
    selectedTab: routes.HOME,
    printView: false,
}

const navigation = (state = initialState, action) => {
    switch (action.type) {
        case SET_NAVIGATION_ROUTE:
            return Object.assign({}, state, {
                selectedTab: action.value,
            })
        case SET_PRINT_TRIGGER:
            return Object.assign({}, state, {
                printView: action.value,
            })
        default:
            return state
    }
}

export default navigation
