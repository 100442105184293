import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createNotification, createDialogue } from './actions';
import BackendService from './BackendService';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import CustomTable from './CustomTable/CustomTable.js'
import moment from 'moment'
import _ from 'lodash';

class Feedback extends Component {

    constructor(...args) {
        super(...args);

        this.state = {
            manage: false,
            feedback: [],
            text: ''
        }

        if (this.props.rights[16] !== undefined) {
            BackendService.getFeedback().then((data) => {
                data.map((entry) => {
                    entry.key = entry.id
                    return entry
                })
                this.setState({ feedback: data })
            })
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        BackendService.createFeedback({ feedback: this.state.text }).then(response => {
            let feedback = _.cloneDeep(this.state.feedback)
            feedback.push(response)
            this.setState({
                feedback: feedback
            })
            this.props.dispatch(createNotification('Feedback', 'Feedback gesendet'))
        })
        this.setState({
            text: ''
        })
    }

    handleDelete() {
        let id = this.state.selected.id

        let deleteAction = (response => {
            if (response === true) {
                BackendService.deleteFeedback(id).then(response => {
                    let feedback = _.filter(this.state.feedback, (item) => item.id !== id)
                    this.setState({
                        feedback,
                        selected: undefined
                    })
                })
            }
        })

        this.props.dispatch(createDialogue('feedback',
            {
                title: 'Löschen',
                text: 'Diese Aktion kann nicht rückgängig gemacht werden.',
                buttons: [
                    {
                        text: 'Löschen',
                        variant: 'primary',
                        value: true
                    },
                    {
                        text: 'Abbrechen',
                        value: false,
                        cancel: true
                    }
                ],
                callback: deleteAction
            },
            'cacel',
        ))
    }

    render() {
        return (
            <Container>
                <Row style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <span>{this.state.manage === true ? 'Feedback verwalten:' : 'Ihr Feedback:'}</span>
                    {(this.props.rights[16] !== undefined && this.state.manage !== true) &&
                        <Button onClick={() => this.setState({ manage: true })}>Feedback verwalten</Button>
                    }
                </Row>

                {this.state.manage !== true &&
                    <Row style={{ flexGrow: 1, marginTop: '10px' }}>
                        <Form onSubmit={e => this.handleSubmit(e)} style={{ width: '100%' }}>
                            <Form.Row style={{ width: '100%' }}>
                                <Form.Group controlId='feedbackText' style={{ width: '100%' }}>
                                    <Form.Control as="textarea" value={this.state.text} onChange={(e) => this.setState({ text: e.target.value })} rows={6} required />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row style={{ justifyContent: 'flex-end' }}>
                                <Button type='submit'>Feedback senden</Button>
                            </Form.Row>
                        </Form>
                    </Row>
                }
                {this.state.manage === true &&
                    [<Row key='table'>
                        <CustomTable
                            initialSortKey='datecreated'
                            sortDirection='desc'
                            hideSearch
                            collumns={[
                                {
                                    title: 'Datum / Uhrzeit',
                                    key: 'datecreated',
                                    cell: (row) => {
                                        return (
                                            <span>{moment(row.datecreated.substring(0, 19)).format("DD.MM.YYYY HH:mm")}</span>
                                        )
                                    },
                                },
                                {
                                    sortable: false,
                                    title: 'Benutzer',
                                    key: 'ersteller',
                                    cell: (row) => {
                                        return (
                                            <span>{row.ersteller.vorname + ' ' + row.ersteller.nachname}</span>
                                        )
                                    },
                                },
                                {
                                    title: 'Feedback',
                                    key: 'feedback',
                                    cell: (row) => {
                                        let text = row.feedback
                                        let textArray = text.split('\n').map((item, key) => {
                                            return <span key={key}>{item}<br /></span>
                                        })
                                        return (
                                            <div>{textArray}</div>
                                        )
                                    },
                                },
                            ]}
                            data={this.state.feedback}
                            onSelect={(row) => {
                                this.setState({ selected: row })
                            }}
                        />
                    </Row>,
                    <Row style={{ justifyContent: 'flex-end' }} key='delete'>
                        <Button onClick={() => this.handleDelete()} disabled={this.state.selected === undefined}>Feedback löschen</Button>
                    </Row>]
                }

            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rights: state.userData.rights,
    }
}

export default connect(mapStateToProps)(Feedback)
