import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import navigation from "./navigation.js";
import userData from './userData.js';
import vaView from './vaView';
import dialogues from './dialogues'
import notification from './notification'

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    navigation,
    userData,
    vaView,
    dialogues,
    notification
})
export default createRootReducer