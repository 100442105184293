export default class Base64Converter {

    static toBase64(file) {
        return new Promise((resolve, reject) => {
            if (file===undefined) {
                resolve('');
            }
            let base64 = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onerror = function (error) {
                reader.abort()
                reject(new DOMException("Problem parsing input file."));
            };
            reader.onload = function () {
                base64 = reader.result;
                resolve(base64);
            };
        });
    }
}