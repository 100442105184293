import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeNotification } from './actions';
import Toast from 'react-bootstrap/Toast'

class Notifications extends Component {

    render() {
        let toasts = []
        this.props.notifications.forEach(notification => {
            toasts.push(
                <Toast show={notification.show} onClose={() => this.props.dispatch(removeNotification(notification))} style={{ minWidth: '300px' }}>
                    <Toast.Header>
                        <strong className="mr-auto">{notification.title}</strong>
                        <small>jetzt</small>
                    </Toast.Header>
                    <Toast.Body>{notification.message}</Toast.Body>
                </Toast>
            )
        });

        return (
            <div style={{ position: 'fixed', top: '30px', right: '30px', }}>


                {toasts}


            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        notifications: state.notification.messages
    }
}

export default connect(mapStateToProps)(Notifications)