import {SET_VA_SELECTED} from '../actions/actionTypes'

const initialState = {
    selectedVA: 0,
    selectedVAObj: {}
}

const vaView = (state = initialState, action) => {
    switch (action.type) {
        case SET_VA_SELECTED:
            return Object.assign({}, state, {
                selectedVA: action.value.id,
                selectedVAObj: action.value
            })
        default:
            return state
    }
}

export default vaView