import React, {Component} from 'react';
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import BackendService from '../../BackendService.js';
import {connect} from 'react-redux';
import {createNotification} from '../../actions';


class EventCopyForm extends Component {

    constructor(props) {
        super(props)

        this.state = {

        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(e) {
        e.preventDefault()
        this.setState({
            inProgress: true
        })
        BackendService.copyVA({
            va: this.props.VA.id,
            datum: this.state.newDate
        }).then(response => {
            this.props.dispatch(createNotification('Veranstaltung', 'Kopieren erfolgreich'))
            this.props.succsessCB(response)
        })
    }

    render() {
        if (this.state.inProgress === true) {
            return (
                <div>
                    <Row>
                        <Col xs='auto'>
                            <Spinner animation="border" />
                        </Col>
                        <Col xs='auto'>
                            Kopiere Veranstaltung...
                    </Col>
                    </Row>
                </div>
            )
        }
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row style={{border: "1px solid grey", padding: "10px", marginBottom: "20px"}}>
                        <Form.Group controlId='info' style={{marginBottom: 0}}>
                            <Form.Label style={{marginBottom: 0}}>
                                <strong>&#9432; Hinweis: </strong> Möglicherweise sind Elemente aus Werbemittel/Präsente/Material und Betreuerausrüstung in der ausgewählten Veranstaltung nicht auf dem aktuellsten Stand. Sie werden lediglich aus der ausgewählten Veranstaltung kopiert und nicht mit der Masterliste abgeglichen.
                            </Form.Label>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group controlId='lable'>
                            <Form.Label>
                                <strong>Veranstaltung:</strong> {this.props.VA.bez}
                            </Form.Label>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group controlId='bis'>
                            <Form.Label><strong>Veranstaltungsstart-Datum:</strong></Form.Label>
                            <Form.Control type="date"
                                value={this.state.newDate != null ? this.state.newDate.substring(0, 10) : null}
                                onChange={(e) => this.setState({newDate: e.target.value})}
                                required />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row style={{justifyContent: 'flex-end'}}>
                        <Col xs='auto'>
                            <Button onClick={this.props.onCancel}>Abbrechen</Button>
                        </Col>
                        <Col xs='auto'>
                            <Button type='submit'>Kopieren</Button>
                        </Col>
                    </Form.Row>
                </Form>
            </div>
        )
    }
}

export default connect()(EventCopyForm)
