import React from 'react'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import CustomTable from '../../CustomTable/CustomTable.js'
import FormAusruestung from './FormAusruestung'
import BaseViewAusruestung from './BaseViewAusruestung'

import {connect} from 'react-redux'
import {setPrintTrigger} from '../../actions'

import BackendService from '../../BackendService'

import _ from 'lodash'

class ViewAusruestungBestand extends BaseViewAusruestung {

  render() {
    return (
      <div className={this.props.className}>
        <Container fluid>
          <Row style={{margin: "5px 0"}}>
          <Col xs="auto">
            <Button onClick={this.handleNew}>Neuer Eintrag</Button>
          </Col>
          <Col xs="auto">
            <Button 
              onClick={this.handleCopy} 
              disabled = {this.state.selected.id == 0}>
                Eintrag kopieren
            </Button>
          </Col>  
          </Row>
          <Row className="max-height-wrapper-2">
            <Col xs={8} className="customRow">
              <CustomTable
                ref={this.table}
                initialSortKey={'artikel'}
                collumns={[
                  {
                    title: 'Bild',
                    key: 'previews',
                    cell: row => {
                      let images = this.state.selectingFromBestand
                        ? this.state.bestandImages[row.key]
                        : this.state.images[row.key]
                      if (images !== undefined) {
                        let src = _.find(
                          row.previews,
                          preview => preview.mainImage,
                        )
                        if (src && src.id) {
                          let srcID = src.id
                          return (
                            <div
                              key={row.id + '_preview'}
                              style={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                overflow: 'hidden',
                              }}
                            >
                              <img
                                src={images[srcID]}
                                alt=""
                                style={{maxHeight: '100px', maxWidth: '100px'}}
                              />
                            </div>
                          )
                        }
                      }
                    },
                  },
                  {
                    title: 'Artikel',
                    key: 'artikel',
                  },
                  {
                    title: 'Artikelausführung (Material, Besonderheit)',
                    key: 'ausfuerung',
                  },
                  {
                    title: 'Logo',
                    key: 'logo',
                  },
                  {
                    title: 'Lagerort',
                    key: 'lagerort',
                  },
                  {
                    title: 'Anschaffungsdatum',
                    key: 'angeschafftdatum',
                  },
                ]}
                data={this.state.data}
                onSelect={row => {
                  this.setState({selected: row})
                }}
              />
            </Col>
            <Col xs={4} className="customRow">
              <FormAusruestung
                va={this.props.id}
                disabled={this.state.selected.id === 0}
                selected={this.state.selected}
                userKuerzel={
                  this.state.kuerzel[this.state.selected.angelegt_von] || ''
                }
                refresh={this.refresh}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                bestand
                canManage="Ausrüstung"
              />
            </Col>
          </Row>
          <Modal
            show={this.state.printing === true}
            centered
            onHide={() => this.setState({printing: false})}
          >
            <Modal.Header closeButton>
              <Modal.Title>Drucken/PDF erstellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.printing && (
                <span>PDF der Ausrüstung wird erstellt...</span>
              )}
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    )
  }

  print() {
    if (this.state.printing !== true) {
      let sortData = {
        sort: {
          [this.table.current.state.sortedCollumn]:
            this.table.current.state.sortDirection,
        },
      }
      this.props.dispatch(setPrintTrigger(false))
      this.setState({
        printing: true,
      })
      BackendService.printAusruestung(0, sortData).then(response => {
        this.setState({
          printing: false,
        })
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.triggerPrint !== prevProps.triggerPrint) {
      this.print()
    }
  }
}

const mapStateToProps = state => {
  return {
    userId: state.userData.user.benutzer_id,
    triggerPrint: state.navigation.printView,
  }
}

export default connect(mapStateToProps)(ViewAusruestungBestand)
