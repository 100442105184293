import {Component} from 'react';

import BackendService from '../../../BackendService.js';

import _ from 'lodash';

class ChecklistView extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.setState = this.setState.bind(this);
        this.removeReminder = this.removeReminder.bind(this);
        this.removeAllReminder = this.removeAllReminder.bind(this);
        this.handleSaveSort = this.handleSaveSort.bind(this)

        this.state = {
            reminders: {}
        }

        BackendService.getVAUsers(this.props.id).then(
            users => {
                this.setState({
                    users
                })
            }
        )

        this.refresh();
    }

    removeReminder() {
        let selectedID = this.state.selectedElement.id
        BackendService.deleteReminder(this.state.selectedElement.id).then(response => {
            let reminders = _.cloneDeep(this.state.reminders)
            reminders[selectedID] = undefined
            this.setState({
                reminders
            })
        })
    }

    removeAllReminder() {
        BackendService.deleteAllReminder(this.props.id).then(response => {
            this.setState({
                reminders: {}
            })
        })
    }

    refresh(callback) {
        BackendService.getChecklistElements(this.props.id, this.props.listTyp).then(
            clItems => {
                clItems.forEach(item => {
                    item.key = item.id
                    item.zustaendig = item.zustaendig === undefined ? {benutzer_id: ''} : item.zustaendig
                    if (item.datum_bis !== undefined && item.datum_bis < item.datum) {
                        let tmp = item.datum;
                        item.datum = item.datum_bis
                        item.datum_bis = tmp
                    }
                })

                let data = this.sortClItems(clItems)

                this.setState({
                    checklistItems: data,
                    groupedChecklistItems: this.groupCLItems(_.cloneDeep(clItems)),
                    loading: false
                })
                if (callback !== undefined) {
                    callback()
                }
            }
        )

        BackendService.getReminder(this.props.id).then(response => {
            let reminders = _.keyBy(response, 'clelement_id')

            this.setState({
                reminders
            })
        })
    }

    sortClItems(clItems) {
        let data = _.sortBy(clItems, ['datum', 'sort']);
        let highestSort = 0
        data.forEach(row => {
            row.sort = highestSort++
        });

        return data
    }

    groupCLItems(checklist) {
        let toGroup = _.filter(checklist, (element) => element.zu_element !== undefined)
        checklist = _.pullAll(checklist, toGroup)
        toGroup.forEach(element => {
            let parent = _.find(checklist, (item) => item.id === element.zu_element)
            if (parent === undefined) {
                return;
            }
            if (parent.subElemnts === undefined) {
                parent.subElemnts = []
            }
            parent.subElemnts.push(element)
        });
        return checklist
    }

    transformDate = date => {
        let transformed = date.toLocaleDateString(
            'de-DE',
            {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
            }
        )
        return transformed
    }

    checkHoliday = (date, propIdentifier, target = 'selectedElement') => {
        BackendService.checkHoliday(date).then(day => {
            if (day.isHoliday) {
                if (Array.isArray(propIdentifier)) {
                    let obj = {}
                    propIdentifier.forEach((ele, idx) => {
                        obj[ele] = day
                    })
                    this.setState((state) => state[target].holidays = obj)
                } else {
                    this.setState((state) => state[target].holidays[propIdentifier] = day)
                }
            }
        })
    }

    handleSelect(element) {
        let selected = _.cloneDeep(element)
        selected.bez = selected.bez === undefined ? "" : selected.bez
        selected.ort = selected.ort === undefined ? "" : selected.ort
        selected.aufgabe = selected.aufgabe === undefined ? "" : selected.aufgabe
        selected.ist_aufgabe = selected.ist_aufgabe === undefined ? false : selected.ist_aufgabe

        let dateFrom = this.transformDate(new Date(selected.datum))
        let dateTo = this.transformDate(new Date(selected.datum_bis))

        selected.holidays = {
            dateFrom: false,
            dateUntil: false
        }

        // Check both with logic from Backend
        if (dateFrom != dateTo) {
            this.checkHoliday(dateFrom, "dateFrom")
            this.checkHoliday(dateTo, "dateUntil")
        } else {
            // Equal Dates, check only 1
            this.checkHoliday(dateFrom, ["dateFrom", "dateUntil"])
        }

        this.setState({
            selectedElement: selected,
            origElement: selected,
            bemerkungen: []
        })

        BackendService.getBemerkungen(selected.id).then(
            bemerkungen => {
                bemerkungen.map(bemerkung => bemerkung.key = bemerkung.id)
                this.setState({
                    bemerkungen
                })
            }
        )
    }

    handleChange = (e, target) => {
        let keys = e.target.id.split('.');
        let value = e.target.value

        let dateprop = null;

        switch (e.target.id) {
            case "datum":
                dateprop = "dateFrom"
                break;
            case "datum_bis":
                dateprop = "dateUntil"
                break;
            default:
                break;
        }

        if (dateprop !== null) {
            // clear previous
            this.state[target].holidays = {
                dateFrom: false,
                dateUntil: false
            }
            const d = this.transformDate(new Date(e.target.value))
            this.checkHoliday(d, dateprop, target)
        }

        let prevSelected = _.cloneDeep(this.state[target])
        this.setDeepChild(prevSelected, keys, value)

        this.setState(prevState => (
            {
                [target]: prevSelected
            }))
    };

    handleChangeCheckbox = (e, target) => {
        let key = e.target.id;
        let value = e.target.checked === true ? true : false
        this.setState(prevState => ({
            [target]: {
                ...prevState[target],
                [key]: value
            }
        }))
    };

    setDeepChild(obj, path, value) {
        let ret = obj
        for (let index = 0; index < path.length - 1; index++) {
            if (ret[path[index]] === undefined) {
                ret[path[index]] = {}
            }
            ret = ret[path[index]];
        }
        ret[path[path.length - 1]] = value
        return ret;
    }

    sort(row, clItems, direction) {
        let tmpIndex = _.indexOf(clItems, row)
        if ((tmpIndex === clItems.length - 1 && direction > 0) || (tmpIndex === 0 && direction < 0)) {
            return
        }
        let index = _.findIndex(clItems, (clItem) => clItem.id === row.id)
        let otherIndex = _.findIndex(clItems, (clItem) => clItem.id === clItems[tmpIndex + direction].id)

        if (clItems[tmpIndex + direction].datum !== row.datum) {
            return;
        }
        let tmpSort = clItems[otherIndex].sort
        let newData = _.cloneDeep(clItems)
        newData[otherIndex].sort = row.sort;
        newData[index].sort = tmpSort;

        this.setState({
            checklistItems: newData,
            groupedChecklistItems: this.groupCLItems(_.cloneDeep(newData)),
            selectedElement: newData[index]
        })


    }

    handleSaveSort() {
        let sortlist = this.table.current.getSortedIds()

        return BackendService.sortChecklistElement({sortlist: sortlist})
    }
}

export default ChecklistView
