import {
    Ability,
    AbilityBuilder
} from "@casl/ability"

import _ from 'lodash';

// Defines how to detect object's type
function subjectName(item) {
    if (!item || typeof item === "string") {
        return item
    }

    return item.constructor.name;
}

const ability = new Ability([], {
    subjectName
})

function getVaUserIds(va) {
    return {
        vaLeitung: va.leitung !== undefined ? va.leitung.benutzerid : -1,
        vaLeitungStellvertreter: va.leitung2 !== undefined ? va.leitung2.benutzerid : -1,
        vaLeitungExtern: va.leitungExtern !== undefined ? va.leitungExtern.benutzerid : -1,
        vaLeitungExternStellvertreter: va.leitungExtern2 !== undefined ? va.leitungExtern2.benutzerid : -1
    }
}

function defineVaAbilities(user, va) {
    const {
        can,
        cannot,
        rules
    } = new AbilityBuilder();

    let userAbilities = defineAbilitiesFor(user)
    const userId = user.user.benutzer_id
    const userGroups = Object.keys(user.groups)
    const userRights = Object.keys(user.rights)
    const vaUserIds = getVaUserIds(va)

    let isVaAdmin = false;

    if (userId === vaUserIds.vaLeitung
        || userId === vaUserIds.vaLeitungExtern
        || userId === vaUserIds.vaLeitungStellvertreter
        || userId === vaUserIds.vaLeitungExternStellvertreter
    ) {
        isVaAdmin = true;

        can('edit', 'VA')
        can('create', 'Beteiligte')
        can('manage', 'Beteiligte')

        // Vorbereitung in VA
        can('edit', 'Vorbereitung')
        can('create', 'Vorbereitung')

        // Werbemittel in VA
        can('manage', 'VA.Werbemittel')
        can('create', 'VA.Durchführung')
        can('manage', 'VA.Ausrüstung')

        // Alle VA Forms sind nicht disabled
        can('edit', 'VA.Forms')

    }

    // 5 => Q:
    // 3 => SBC2
    if (isVaAdmin || _.includes(userGroups, '5') || _.includes(userGroups, '3')) {
        // Auch Inaktive Checklisten Elemente
        can('see', 'InactiveChecklistElement')
    }

    // 2 => Neuanlage Benutzer
    if (_.includes(userRights, '2')) {
        can('edit', 'VA.NewBeteiligterForm')
    }

    return [...userAbilities, ...rules]
}

function defineAbilitiesFor(user) {

    const {
        can,
        cannot,
        rules
    } = new AbilityBuilder();

    const userRights = Object.keys(user.rights);
    const userGroups = Object.keys(user.groups)
    const userRoles = Object.keys(user.roles);

    can('edit', 'VA')
    can('create', 'VA.Durchführung')

    // Beteiligte in VA
    can('create', 'Beteiligte')
    can('manage', 'Beteiligte')

    // Vorbereitung in VA
    can('edit', 'Vorbereitung')
    can('create', 'Vorbereitung')

    // Werbemittel in VA
    can('manage', 'VA.Werbemittel')

    if (_.includes(userRights, "20") || _.includes(userRoles, ["1", "4"])) {
        can('manage', 'VA.Ausrüstung')
        can('manage', 'Ausrüstung')
    }

    if (_.includes(userGroups, "6")) {
        cannot('edit', 'VA')
        cannot('create', 'VA.Durchführung')
        cannot('create', 'Beteiligte')
        cannot('manage', 'Beteiligte')
        cannot('edit', 'Vorbereitung')
        cannot('create', 'Vorbereitung')
        cannot('manage', 'VA.Werbemittel')
        cannot('manage', 'VA.Ausrüstung')
        cannot('manage', 'Ausrüstung')
    }

    return rules;
}


export {
    ability,
    defineAbilitiesFor,
    defineVaAbilities,
}
