import React, {Component} from 'react'

import BackendService from '../../../BackendService.js'
import {connect} from 'react-redux'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import BeteiligteForm from './BeteiligteForm'

import CustomTable from '../../../CustomTable/CustomTable.js'
import {ability} from '../../../config/ability'

class NewBeteiligterForm extends Component {
  constructor(props) {
    super(props)

    this.beteiligterForm = React.createRef()

    this.state = {
      users: [],
      selected: props.selectedBeteiligter,
      showUsers: false,
    }

    this.selectUser = this.selectUser.bind(this)
    this.enterUserSelectMode = this.enterUserSelectMode.bind(this)
    this.exitUserSelectMode = this.exitUserSelectMode.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    BackendService.getUsers().then(users => {
      users.map(user => {
        user.id = user.benutzer_id
        user.key = user.id
        return this
      })

      this.setState({
        users,
      })
    })
  }

  selectUser(user) {
    let selection = {
      ...this.state.selected,
      funktion: '',
      namefirma: '' + user.nachname + ' ' + user.vorname + ', ' + user.firma,
      anschrift: '' + user.strasse + ' ' + user.plz + ' ' + user.ort,
      mobil: '' + user.mobil,
      telefon: '' + user.telefon,
      fax: '' + user.fax,
      email: '' + user.email,
      www: '' + user.www,
    }

    this.setState({
      selected: selection,
    })
  }

  enterUserSelectMode() {
    this.setState(prevState => ({
      tmpSelected: prevState.selected,
      showUsers: true,
    }))
  }

  exitUserSelectMode(confirm) {
    this.setState(prevState => ({
      tmpSelected: undefined,
      selected: confirm ? prevState.selected : prevState.tmpSelected,
      showUsers: false,
    }))
  }

  handleSubmit(args) {
    this.props.handleNewSave()
  }

  render() {
    return (
      <Container fluid>
        <Row>
          {this.state.showUsers === false &&
            this.props.rights[2] !== undefined && (
              <Col xs={1}>
                <Button
                  style={{
                    whiteSpace: 'nowrap',
                    marginBottom: '10px',
                  }}
                  onClick={this.enterUserSelectMode}
                >
                  Vorhandene Beteiligte übernehmen
                </Button>
              </Col>
            )}
        </Row>
        <Row>
          {this.state.showUsers === true && (
            <Col xs={5}>
              <Row>
                <CustomTable
                  collumns={[
                    {
                      title: 'Benutzer',
                      key: 'nachname',
                      cell: row => (
                        <span key={row.id + '_nachname'}>
                          {row.nachname + ', ' + row.vorname + ', ' + row.firma}
                        </span>
                      ),
                    },
                  ]}
                  data={this.state.users}
                  initialSortKey="nachname"
                  onSelect={this.selectUser}
                />
              </Row>
              <Row>
                <Button onClick={() => this.exitUserSelectMode(false)}>
                  Abbrechen
                </Button>
              </Row>
            </Col>
          )}
          <Col>
            <BeteiligteForm
              new
              ref={this.beteiligterForm}
              closeModal={this.handleSubmit}
              selected={this.state.selected}
              disabled={!ability.can('edit', 'VA.NewBeteiligterForm')}
              refresh={this.props.refresh}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    rights: state.userData.rights,
  }
}

export default connect(mapStateToProps)(NewBeteiligterForm)
