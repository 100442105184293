import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { resolveDialogue, createDialogue } from './actions';
import _ from 'lodash';


// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class DialogProvider extends Component {

    constructor(props) {
        super(props)
        this.cancelDialogue = this.cancelDialogue.bind(this)
    }

    cancelDialogue(key) {
        let actionValue = _.find(this.props.dialogues[key].buttons, button => button.cancel === true).value
        this.props.dispatch(resolveDialogue(key, actionValue))
    }

    render() {
        let modals = []

        if (this.props.dialogues !== undefined) {
            _.forEach(this.props.dialogues, function (dialogue, key) {
                if (dialogue.resolved !== undefined) {
                    return;
                }
                let buttons = []
                if (dialogue.buttons !== undefined) {
                    dialogue.buttons.forEach(button => {
                        buttons.push(
                            <Button
                                variant={button.variant !== undefined ? button.variant : "secondary"}
                                onClick={() => this.props.dispatch(resolveDialogue(key, button.value))}
                            >
                                {button.text}
                            </Button>
                        )
                    });
                    _.reverse(buttons)
                }

                let cacelAction = () => this.cancelDialogue(key, true)
                modals.push(
                    <Modal
                        show={true}
                        centered
                        onHide={cacelAction}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{dialogue.title}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <p>{dialogue.text}</p>
                        </Modal.Body>

                        <Modal.Footer>
                            {buttons}
                        </Modal.Footer>
                    </Modal>
                )
            }.bind(this));
        }


        return (
            <div>{modals}</div>

        )
    }

    static create(key,dialogue,rejectValue=''){
        if (this!==undefined) {
            this.props.dispatch(createDialogue(dialogue));
            return new Promise((resolve,reject)=>{
                if (this.props.dialogues[key].resolved!==undefined) {
                    if (this.props.dialogues[key].resolved===rejectValue) {
                        reject(rejectValue)
                    } else {
                        resolve(this.props.dialogues[key].resolved)
                    }
                }
            })
        }
        return undefined
    }
}

const mapStateToPropsSeries = (state) => {
    return {
        dialogues: state.dialogues
    }
}

export default connect(mapStateToPropsSeries)(DialogProvider)