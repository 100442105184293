import React from "react";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "react-bootstrap/Button";
import BackendService from "../../../BackendService";
import Base64Converter from "../../../Base64Converter";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import BaseForm from "../../../forms/BaseForm";
import { createDialogue, createNotification } from "../../../actions";
import { connect } from "react-redux";

import Can from "../../../config/Can";

import { isEqual } from 'lodash';

class BeteiligteForm extends BaseForm {
  constructor(...args) {
    super(...args);

    this.default = {
      selected: {
        ...this.props.selected,
        namefirma: "",
        anschrift: "",
        telefon: "",
        mobil: "",
        fax: "",
        www: "",
        email: "",
        bemerkungen: "",
        va: this.props.selected.va,
        funktion: "",
        erstellt_von: "",
        dt_erstellt: null,
      },
    };

    this.state = this.default;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.onEditImage = this.onEditImage.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(prevProps.selected,this.props.selected)){
        this.reset();
    }  
  }


  renderImage() {
    if (this.state.newImage == undefined && this.state.image !== undefined) {
      return (
        <div
          style={{
            backgroundColor: "fff",
            border: "1px solid #495057",
          }}
        >
          <div
            style={{
              width: "143.281px",
              height: "215px",
              backgroundImage: `url(${this.state.image})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
          <div
            style={{
              width: "100%",
              backgroundColor: "#002d59",
              height: "40px",
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <FontAwesomeIcon
              icon="trash"
              size="lg"
              style={{
                color: "#fff",
                height: "90%",
                width: "auto",
                padding: "5% 10px",
              }}
              onClick={() => this.deleteImage(this.state.selected)}
            />
          </div>
        </div>
      );
    }else if(this.state.newImage !== undefined){
      return (
        <img
          src={this.state.newImage}
          alt=""
          style={{
            padding: "5px",
            height: "225px",
            width: "auto",
          }}
        />
      );
    }else{
      return <p style={{textAlign: "center", fontSize: "small"}}>Kein Bild vorhanden</p>
    }
  }

  handleImageUpload() {
    let base64 = this.state.newImage.replace(/^data:.+;base64,/, "");
    let data = {
      name: "beteiligter_" + this.state.selected.id + "_preview",
      content: base64,
    };

    let uploadAction = (response) => {
      if (response === true) {
        BackendService.createBeteiligterFile(this.state.selected.id, data).then(() => {
          this.setState({ newImage: undefined })
          this.refreshImage()
          createNotification("Beteiligte", "Bild wurde hochgeladen")
        }).catch((err) => {
            console.log(err);
        });
      }else{
        this.setState({ newImage: undefined })
      }
    };

    if(this.state.selected.preview !== undefined){

      this.props.dispatch(
        createDialogue(
          "beteiligter",
          {
            title: "Bild ersetzen",
            text: "Es ist bereits ein Bild vorhanden. Möchten Sie das vorhandene Bild ersetzen? ",
            buttons: [
              {
                text: "Ja",
                variant: "primary",
                value: true,
              },
              {
                text: "Nein",
                value: false,
                cancel: true,
              },
            ],
            callback: uploadAction,
          },
          "cancel"
        )
      );
    }else{
      uploadAction(true);
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    BackendService.editBeteiligter(this.state.selected).then(
        (beteiligter) => {
          
          if (this.props.new){
            this.props.closeModal();
          }else{
            this.props.dispatch(
              createNotification("Beteiligte", "Eintrag bearbeitet")
            );
          }
          this.props.refresh();
        }
    )
  }

  deleteImage(beteiligter) {

    BackendService.deleteBeteiligterFile(beteiligter.id, { id: beteiligter.id }).then(() => {

      this.refreshImage()

        if(!this.props.new){
          this.props.dispatch(createNotification('Beteiligte', 'Bild wurde gelöscht')) 
        }       
    })
  }

  handleDelete() {
    let deleteAction = (response) => {
      if (response === true) {
        BackendService.deleteBeteiligter(this.state.selected).then((data) => {
          this.props.onDelete()
          this.props.dispatch(
            createNotification("Beteiligte", "Löschen erfolgreich")
          );
        });
      }
    };

    this.props.dispatch(
      createDialogue(
        "beteiligter",
        {
          title: "Löschen",
          text: "Diese Aktion kann nicht rückgängig gemacht werden.",
          buttons: [
            {
              text: "Löschen",
              variant: "primary",
              value: true,
            },
            {
              text: "Abbrechen",
              value: false,
              cancel: true,
            },
          ],
          callback: deleteAction,
        },
        "cancel"
      )
    );
  }

  onEditImage(e) {
    let file = e.target.files[0];
    Base64Converter.toBase64(file).then((base64) => {
      this.setState({
        newImage: base64,
      });
    });
  }

  reset() {
    super.reset();
    this.setState({
      file: "",
      image: undefined,
      newImage: undefined,
    });

    if (this.props.selected.preview) {
      let id = this.props.selected.id;
      BackendService.getBeteiligterFile(this.props.selected.id).then((data) => {
        if (this.state.selected.id === id) {
          this.setState({
            image: data,
          });
        }
      });
    }
  }

  refreshImage(){
    BackendService.getBeteiligterById(this.state.selected.id).then((data) => {
      if (data.preview) {
        BackendService.getBeteiligterFile(data.id).then((data) => {
            this.setState({
              image: data,
            });
        });
      }else{
        this.setState({
          image: undefined,
        });
      }
    })

  }

  render() {
    super.render();
    return (
      <Form onSubmit={(e) => this.handleSubmit(e)}>
        <Form.Row>
          <Col>
            <Form.Group controlId="namefirma">
              <Form.Label>Vor- und Nachname / Firma:</Form.Label>
              <Form.Control
                as="textarea"
                value={this.state.selected.namefirma}
                onChange={this.handleChange}
                rows={4}
                disabled={this.props.disabled}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="anschrift">
              <Form.Label>Anschrift:</Form.Label>
              <Form.Control
                as="textarea"
                value={this.state.selected.anschrift}
                onChange={this.handleChange}
                rows={4}
                disabled={this.props.disabled}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group controlId="funktion">
              <Form.Label>Funktion:</Form.Label>
              <Form.Control
                type="text"
                value={this.state.selected.funktion}
                disabled={this.props.disabled}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group controlId="mobil">
              <Form.Label>Mobil:</Form.Label>
              <Form.Control
                type="text"
                value={this.state.selected.mobil}
                onChange={this.handleChange}
                disabled={this.props.disabled}
              />
            </Form.Group>
            <Form.Group controlId="bemerkungen">
              <Form.Label>Bemerkung:</Form.Label>
              <Form.Control
                as="textarea"
                value={this.state.selected.bemerkungen}
                disabled={this.props.disabled}
                onChange={this.handleChange}
                rows={8}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="telefon">
              <Form.Label>Telefon +49 (0)123 456789:</Form.Label>
              <Form.Control
                type="text"
                value={this.state.selected.telefon}
                onChange={this.handleChange}
                disabled={this.props.disabled}
              />
            </Form.Group>
            <Form.Group controlId="image">
              <Form.Label>Portraitfoto (3x2 Format, Hochkant):</Form.Label>
              <Row style={{ justifyContent: "center", padding: "10px" }}>
                {this.renderImage()}
              </Row>
              <Row style={{ justifyContent: "center", padding: "10px" }}>

                <Can I="edit" a="VA">
                  {this.state.newImage == undefined ? (
                    <Form.Control
                      key="selectImage"
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={this.onEditImage}
                      disabled={this.props.disabled}
                    />
                  ) : (
                    <Row>
                      <Col>
                        <Button
                          onClick={(e) => {
                            this.setState({ newImage: undefined });
                          }}
                        >
                          Abbrechen
                        </Button>
                      </Col>
                      <Col>
                        <Button onClick={this.handleImageUpload}>
                          Hochladen
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Can>
              </Row>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group controlId="email">
              <Form.Label>E-Mail:</Form.Label>
              <Form.Control
                type="text"
                value={this.state.selected.email}
                onChange={this.handleChange}
                disabled={this.props.disabled}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="www">
              <Form.Label>www:</Form.Label>
              <Form.Control
                type="text"
                value={this.state.selected.www}
                onChange={this.handleChange}
                disabled={this.props.disabled}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Can I="edit" a="VA">
          <Form.Row>
            <Col>
              {!this.props.new && (
                <Button
                  onClick={this.handleDelete}
                  disabled={this.props.disabled}
                >
                  Löschen
                </Button>
              )}
            </Col>
            <Col>
              <Button type="submit">Speichern</Button>
            </Col>
          </Form.Row>
        </Can>
      </Form>
    );
  }
}

export default connect()(BeteiligteForm);
