import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ability } from "../../../config/ability";
import Can from "../../../config/Can";
import DatePicker from "../../../DatePicker";

class ChecklistFormPrePost extends Component {
  render() {
    let disableForm = this.props.disabled;
    let blockEdits = !this.props.new;
    let dateId = this.props.new ? "newDatePicker_date" : "datePicker_date";
    blockEdits = blockEdits && !ability.can("edit", "VA.Forms");
    return (
      <Container fluid>
        <Form onSubmit={(e) => this.props.handleSubmit(e)}>
          <Form.Row>
            <Col>
              {!this.props.new && (
                <Form.Group as={Row} controlId="erledigt">
                  <Col sm={{ span: 10 }}>
                    <Form.Check
                      checked={this.props.selectedElement.erledigt}
                      label="erledigt"
                      onChange={this.props.handleChangeCheckbox}
                      disabled={disableForm}
                    />
                  </Col>
                </Form.Group>
              )}
            </Col>
            <Col>
              {!this.props.new && (
                <Form.Group as={Row} controlId="aktiv">
                  <Col sm={{ span: 10 }}>
                    <Form.Check
                      checked={this.props.selectedElement.aktiv}
                      label="aktiv"
                      onChange={this.props.handleChangeCheckbox}
                      disabled={disableForm || blockEdits}
                    />
                  </Col>
                </Form.Group>
              )}
            </Col>
            {this.props.reminder !== undefined && (
              <Button onClick={this.props.removeReminder}>
                <FontAwesomeIcon icon="exclamation" color="yellow" />
                {" gelesen"}
              </Button>
            )}
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group controlId={this.props.new ? "datum-neu" :"datum"}>
                <Form.Label>Termin:</Form.Label>
                <Form.Control
                  as={DatePicker}
                  type="date"
                  value={
                    this.props.selectedElement.datum != null
                      ? new Date(this.props.selectedElement.datum)
                      : ""
                  }
                  changeValue={this.props.handleChange}
                  disabled={
                    disableForm ||
                    (blockEdits &&
                      this.props.origElement.datum !== null &&
                      this.props.origElement.datum !== "")
                  }
                  valueId={"datum"}
                  locale="de"
                  dateFormat="DD.MM.YYYY"
                  timeFormat="HH:mm"
                  withoutTime={true}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="zustaendig.benutzer_id">
                <Form.Label>zuständig:</Form.Label>
                <Form.Control
                  as="select"
                  value={
                    this.props.selectedElement.zustaendig !== undefined
                      ? this.props.selectedElement.zustaendig.benutzer_id
                      : ""
                  }
                  onChange={this.props.handleChange}
                  disabled={
                    disableForm ||
                    (blockEdits &&
                      this.props.origElement.zustaendig !== undefined &&
                      this.props.origElement.zustaendig.benutzer_id !== "")
                  }
                >
                  {this.props.userOptions}
                </Form.Control>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group controlId="bez">
                <Form.Label>Stichwort</Form.Label>
                <Form.Control
                  type="text"
                  value={this.props.selectedElement.bez}
                  onChange={this.props.handleChange}
                  disabled={
                    disableForm ||
                    (blockEdits && this.props.origElement.bez !== "")
                  }
                />
              </Form.Group>
            </Col>
          </Form.Row>
          {!this.props.new && (
            <Form.Row>
              <Col>
                <Form.Group controlId="erstellt_von">
                  <Form.Label>Ersteller:</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.props.selectedElement.erstellt_von}
                    disabled
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="erledigt_von">
                  <Form.Label>Erledigt von:</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.props.selectedElement.erledigt_von}
                    disabled
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          )}
          <Form.Row>
            <Col>
              <Form.Group controlId="aufgabe">
                <Form.Label>Beschreibung:</Form.Label>
                <Form.Control
                  as="textarea"
                  value={this.props.selectedElement.aufgabe}
                  onChange={this.props.handleChange}
                  rows={3}
                  disabled={
                    disableForm ||
                    (blockEdits && this.props.origElement.aufgabe !== "")
                  }
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Can I="create" a="Vorbereitung">
              <Form.Group as={Row}>
                <Col>
                  {!this.props.new && (
                    <Button
                      disabled={
                        this.props.selectedElement.zu_element !== undefined ||
                        disableForm
                      }
                      onClick={(e) =>
                        this.props.handleNewSubElement(
                          this.props.selectedElement.id
                        )
                      }
                    >
                      Unteraufgabe Hinzufügen
                    </Button>
                  )}
                  <Button disabled={disableForm} type="submit">
                    Speichern
                  </Button>
                </Col>
              </Form.Group>
            </Can>
          </Form.Row>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    va: state.vaView.selectedVA,
    user: state.userData.user,
    rights: state.userData.rights,
    roles: state.userData.roles,
  };
};

export default connect(mapStateToProps)(ChecklistFormPrePost);
