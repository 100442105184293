import React, {Component} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {setPrintTrigger, createNotification} from '../../actions';
import {connect} from 'react-redux';

import BackendService from '../../BackendService';
import CustomTable from '../../CustomTable/CustomTable'
import CheckmasterForm from './CheckmasterForm';

import _ from 'lodash';

class CheckmasterView extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.setState = this.setState.bind(this);
        this.refresh();

        this.emptyNewElement = {
            "list_type": this.props.listTyp,
            "va_type": 0,
            "bez": "",
            "aufgabe": "",
            "aktiv": true,
            "programmpunkt": false,
        }

        this.state = {
            selectedElement: {
                "id": 0,
                "list_type": 0,
                "va_type": 0,
                "dt_erstellt": null,
                "erstellt_von": '',
                "bez": "",
                "aufgabe": "",
                "aktiv": false,
                "sort": 0,
            },
            newElement: this.emptyNewElement,
            loading: true,
            creatingNew: false,
            cmItems: [],
            types: [],
            selectedType: 10
        }

        this.collumns = []
        if (this.props.listTyp === 3) {
            this.collumns.push({
                title: '',
                key: 'programmpunkt',
                cell: row =>
                    row.programmpunkt ?
                        <FontAwesomeIcon key={row.id + '_icon'} icon={['far', "clock"]} size='lg' /> :
                        <FontAwesomeIcon key={row.id + '_icon'} icon="tasks" size='lg' />

            })
        }

        this.collumns = this.collumns.concat(
            [{
                title: 'Stichwort',
                key: 'bez'
            },
            {
                title: 'Beschreibung',
                key: 'aufgabe'
            },
            {
                title: 'Sortierung',
                key: 'sort'
            },
            {
                title: 'aktiv',
                key: 'aktiv',
                cell: row =>
                    <input key={row.id + '_aktiv'} type="checkbox" disabled checked={row.aktiv} />
            }]
        )

        BackendService.getVATypen().then(
            typen => {
                let activeTypes = []
                typen.forEach(type => {
                    if (type.aktiv) {
                        activeTypes.push(type)
                    }
                });
                this.setState({
                    types: activeTypes,
                    selectedType: activeTypes[0].id
                })
            }
        )

        this.table = React.createRef()
    }

    refresh(callback) {
        BackendService.getCheckmasterElementsByListType(this.props.listTyp).then(
            cmItems => {
                cmItems.forEach(item => {
                    item.key = item.id
                })

                let data = this.sortCmItems(cmItems)

                this.setState({
                    cmItems: data,
                    loading: false
                })
                if (callback !== undefined) {
                    callback()
                }
            }
        )
    }

    sortCmItems(cmItems) {
        let data = _.sortBy(cmItems, ['sort']);
        return data
    }

    handleSelect(element) {
        let selected = _.cloneDeep(element)
        selected.bez = selected.bez === undefined ? "" : selected.bez
        selected.aufgabe = selected.aufgabe === undefined ? "" : selected.aufgabe
        this.setState({
            selectedElement: selected,
        })
    }

    handleChange = (e, target) => {
        let keys = e.target.id.split('.');
        let value = e.target.value
        let prevSelected = _.cloneDeep(this.state[target])
        this.setDeepChild(prevSelected, keys, value)
        this.setState(prevState => (
            {
                [target]: prevSelected
            }))
    };

    handleChangeCheckbox = (e, target) => {
        let key = e.target.id;
        let value = e.target.checked === true ? true : false
        this.setState(prevState => ({
            [target]: {
                ...prevState[target],
                [key]: value
            }
        }))
    };

    setDeepChild(obj, path, value) {
        let ret = obj
        for (let index = 0; index < path.length - 1; index++) {
            if (ret[path[index]] === undefined) {
                ret[path[index]] = {}
            }
            ret = ret[path[index]];
        }
        ret[path[path.length - 1]] = value
        return ret;
    }

    handleSubmit(event) {
        event.preventDefault();
        let data = {
            "id": this.state.selectedElement.id,
            "list_type": this.state.selectedElement.list_type,
            "va_type": this.state.selectedElement.va_type === 0 ? this.state.selectedType : this.state.selectedElement.va_type,
            "dt_erstellt": this.state.selectedElement.dt_erstellt,
            "erstellt_von": this.state.selectedElement.erstellt_von,
            "bez": this.state.selectedElement.bez,
            "aufgabe": this.state.selectedElement.aufgabe,
            "sort": this.state.selectedElement.sort,
            "aktiv": this.state.selectedElement.aktiv,
            "programmpunkt": this.state.selectedElement.programmpunkt,
        }
        this.setState({
            loading: true
        })
        BackendService.editCheckmasterElement(data).then(response => {
            let cmItems = _.cloneDeep(this.state.cmItems)
            let index = _.findIndex(cmItems, (item) => item.id === response.id)
            response.key = response.id
            cmItems[index] = response
            this.setState({
                cmItems: cmItems,
                selectedElemen: response,
                loading: false,
            })
            this.props.dispatch(createNotification('Checklisten', 'Eintrag editiert'))
        })
    }

    handleNew(event) {
        event.preventDefault();
        this.setState({
            loading: true,
            creatingNew: false
        })
        let data = {
            ...this.state.newElement,
            va_type: this.state.selectedType
        }
        BackendService.createCheckmasterElement(data).then(response => {
            let cmItems = _.cloneDeep(this.state.cmItems)
            response.key = response.id
            cmItems.push(response)
            this.setState({
                cmItems: cmItems,
                selectedElemen: response,
                loading: false,
            })
            this.props.dispatch(createNotification('Checklisten', 'Eintrag hinzugefügt'))
        })
    }

    handleDelete(id) {
        BackendService.deleteCheckmasterElement(id).then(response => {
            let cmItems = _.cloneDeep(this.state.cmItems)
            let index = _.findIndex(cmItems, (item) => item.id === id)
            cmItems.splice(index, 1)
            this.setState({
                cmItems: cmItems,
                selectedElement: {
                    "id": 0,
                    "list_type": 0,
                    "va_type": 0,
                    "dt_erstellt": null,
                    "erstellt_von": '',
                    "bez": "",
                    "aufgabe": "",
                    "aktiv": false,
                    "sort": 0,
                }
            })
            this.props.dispatch(createNotification('Checklisten', 'Eintrag gelöscht'))
        })
    }

    render() {
        let disableForm = this.state.loading || this.state.selectedElement === undefined || this.state.selectedElement.id === undefined || this.state.selectedElement.id === 0

        let data = _.filter(this.state.cmItems, (item) => item.va_type + '' === this.state.selectedType + '')

        let typeOptions = []
        this.state.types.forEach(type => {
            typeOptions.push(<option key={type.id} value={type.id}>{type.bez}</option>)
        })

        return (
            <div className={this.props.className}>
                <Container fluid>
                    <Row>
                        <Col xs={8} className="customRow overflow-y-hidden">
                            <Row>
                                <Form style={{paddingBottom: "0"}}>
                                    <Form.Row>
                                        <Col xs='auto'>
                                            <Button onClick={() => this.setState({creatingNew: true, newElement: this.emptyNewElement})}>+ Neuer Eintrag</Button>
                                        </Col>
                                        <Col xs="auto" style={{paddingLeft: '20px', display: 'flex', alignItems: 'flex-end'}}>
                                            <Form.Group as={Row} controlId="vaType" style={{alignItems: 'baseline', margin: '0'}}>
                                                <Form.Label>
                                                    Veranstaltungstyp:
                                                </Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        as="select" value={this.state.selectedType}
                                                        onChange={(e) => {this.setState({selectedType: e.target.value})}}
                                                    >
                                                        {typeOptions}
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Row>
                            <Row className="max-height-wrapper">
                                <CustomTable
                                    ref={this.table}
                                    initialSortKey={'sort'}
                                    collumns={this.collumns}
                                    data={data}
                                    onSelect={(row) => {
                                        this.handleSelect(row)
                                    }}
                                />
                            </Row>

                        </Col>
                        <Col xs={4} style={{padding: '30px'}} className="customRow">
                            <CheckmasterForm
                                disabled={disableForm}
                                during={this.props.listTyp === 3}
                                onDelete={this.handleDelete}
                                handleSubmit={this.handleSubmit}
                                handleChange={(e) => this.handleChange(e, 'selectedElement')}
                                handleChangeCheckbox={(e) => this.handleChangeCheckbox(e, 'selectedElement')}
                                handleNewSubElement={(parentID) => this.setState({creatingNew: true, newElement: {...this.emptyNewElement, zu_element: parentID}})}
                                selectedElement={this.state.selectedElement}
                            />
                        </Col>
                    </Row>


                    <Modal
                        show={this.state.creatingNew}
                        centered
                        onHide={() => this.setState({creatingNew: false})}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Neues Element
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CheckmasterForm
                                during={this.props.listTyp === 3}
                                new
                                disabled={false}
                                handleSubmit={this.handleNew}
                                handleChange={(e) => this.handleChange(e, 'newElement')}
                                handleChangeCheckbox={(e) => this.handleChangeCheckbox(e, 'newElement')}
                                selectedElement={this.state.newElement}
                            />
                        </Modal.Body>
                    </Modal>
                </Container>
            </div>
        )
    }

    print() {
        if (this.props.hidden === true) {
            return
        }
        if (this.state.printing !== true) {
            let sortData = {sort: {[this.table.current.state.sortedCollumn]: this.table.current.state.sortDirection}}
            this.props.dispatch(setPrintTrigger(false))
            this.setState({
                printing: true
            })
            BackendService.printCheckmaster(this.props.listTyp, this.state.selectedType, sortData).then(response => {
                this.setState({
                    printing: false
                })
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.triggerPrint !== prevProps.triggerPrint) {
            this.print();
        }
    }
}

const mapStateToProps = (state) => {
    return {
        triggerPrint: state.navigation.printView,
    }
}

export default connect(mapStateToProps)(CheckmasterView)
