import React, { Component } from 'react';
import { connect } from 'react-redux';
import Login from './Login.js'
import SteveHeader from './SteveHeader.js'
import Navigation from './Navigation.js'
import MainView from './MainView.js'
import BackendService from './BackendService.js'
import { logout, setVaSelected } from './actions';
import DialogProvider from './DialogProvider'
import Notifications from './Notifications'


class AuthGate extends Component {
    constructor(props) {
        super(props);
        BackendService.logoutCallback = () => {this.logout()};
    }

    render() {
        if (this.props.userData != null && this.props.userData.user != null) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '100%' }}>
                    <SteveHeader />
                    <Navigation />
                    <MainView />
                    <DialogProvider/>
                    <Notifications/>
                </div>
            )
        }
        return (
            <div style={{ display: 'flex', height: '100%', maxHeight: '100%', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <Login />
            </div>
        )
    }

    logout(){
        this.props.dispatch(setVaSelected({id: 0 }))
        this.props.dispatch(logout())
    }
}

const mapStateToPropsSeries = (state) => {
    return {
        userData: state.userData,
    }
}

export default connect(mapStateToPropsSeries)(AuthGate)
