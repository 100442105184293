import React from 'react'

import BackendService from '../../../BackendService.js'

import {connect} from 'react-redux'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import {createNotification, setPrintTrigger} from '../../../actions'

import CustomTable from '../../../CustomTable/CustomTable.js'
import FormWerbemittel from '../../Werbemittel/FormWerbemittel'
import BaseViewWerbemittel from '../../Werbemittel/BaseViewWerbemittel'

import _ from 'lodash'

import {ability} from '../../../config/ability'
import Can from '../../../config/Can'

class ViewWerbemittel extends BaseViewWerbemittel {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      bestandData: [],
      selectedBestand: 0,
      bestandImages: {},
      printing: false,
    }

    BackendService.getWerbemittelByVA(0).then(data => {
      const size = 20
      let arrayOfData = []
      for (let index = 0; index < data.length; index += size) {
        arrayOfData.push(data.slice(index, index + size))
      }

      arrayOfData.reduce((currentPromise, elements) => {
        return currentPromise.then(() => {
          let promises = []
          for (let index = 0; index < elements.length; index++) {
            let element = elements[index]
            element.key = element.id
            for (let h = 0; h < element.previews.length; h++) {
              let preview = element.previews[h]
              if (preview.mainImage) {
                promises.push(
                  BackendService.getWerbemittelFile(preview.id).then(data => {
                    let prevImages = _.cloneDeep(this.state.bestandImages)
                    this.setDeepChild(
                      prevImages,
                      [element.key, preview.id],
                      data,
                    )
                    this.setState({
                      bestandImages: prevImages,
                    })
                  }),
                )
                break
              }
            }
          }
          return Promise.all(promises)
        })
      }, Promise.resolve())

      this.setState({
        bestandData: data,
        selectedBestand: 0,
      })
    })

    this.collumns = [
      {
        title: 'Bild',
        key: 'previews',
        cell: row => {
          let images = this.state.selectingFromBestand
            ? this.state.bestandImages[row.key]
            : this.state.images[row.key]
          if (images !== undefined && images ) {
            let src = _.find(
              row.previews,
              function(preview){
                return preview.mainImage
               },
            )
            if (src && src.id) {
              let srcID = src.id
              return (
                <div
                  key={row.id + '_preview'}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={images[srcID]}
                    alt=""
                    style={{maxHeight: '100px', maxWidth: '100px'}}
                  />
                </div>
              )
            }  
          }
        },
      },
      {
        title: 'Artikel',
        key: 'artikel',
      },
      {
        title: 'Artikelausführung (Material, Besonderheit)',
        key: 'ausfuerung',
      },
      {
        title: 'Größe',
        key: 'groesse',
      },
      {
        title: 'Logo',
        key: 'logo',
      },
      {
        title: 'Lagerort',
        key: 'lagerort',
      },
    ]
    this.handleCopyFromMaster = this.handleCopyFromMaster.bind(this)
    this.handleCopy = this.handleCopy.bind(this)
  }

  handleCopyFromMaster() {
    let va = this.props.id;
    BackendService.copyWerbemittel(this.state.selectedBestand.id).then(wm => {
      wm.veranstaltungid = va
      BackendService.editWerbemittel(wm).then(() => {
        this.refresh()
        this.props.dispatch(
            createNotification("Werbemittel", "Eintrag zur Packliste hinzugefügt")
        );
      })
    })
  }

  handleCopy() {
    let va = this.props.id
    BackendService.copyWerbemittel(this.state.selected.id).then(wm => {
        wm.veranstaltungid = va;
        wm.artikel = wm.artikel + " Kopie";
        BackendService.editWerbemittel(wm).then(() => {
          this.refresh()
          this.props.dispatch(
            createNotification("Werbemittel", "Eintrag kopiert")
          );
          this.table.current.setSelectedRowKey(wm.id)
        })
        
    })
  }

  render() {
    return (
      <Container fluid>
        <Can I="manage" a="VA.Werbemittel">
          <Row style={{margin: "20px 0 10px 0"}}>
            <Col xs="auto">
              {!this.state.selectingFromBestand && (
                <Button onClick={this.handleNew}>Neuer Eintrag</Button>
              )}
            </Col>
            <Col xs="auto">
              <Button
                onClick={() =>
                  this.setState(prev => ({
                    selectingFromBestand: !prev.selectingFromBestand,
                  }))
                }
              >
                {this.state.selectingFromBestand ? (
                  <span>Zur Packliste zurückkehren</span>
                ) : (
                  <span>Aus Bestandsliste auswählen</span>
                )}
              </Button>
            </Col>
            {!this.state.selectingFromBestand  && (
            <Col xs="auto">
              <Button
              onClick={this.handleCopy}
              disabled={this.state.selected.id == 0}>
                Eintrag kopieren
              </Button>
            </Col>
            )}
          </Row>
        </Can>
        <Row className="max-height-wrapper">
          <Col xs={8} className="customRow">
            <CustomTable
              ref={this.table}
              initialSortKey={'id'}
              collumns={this.collumns}
              data={
                this.state.selectingFromBestand
                  ? this.state.bestandData
                  : this.state.data
              }
              onSelect={row => {
                this.setState(
                  this.state.selectingFromBestand
                    ? {selectedBestand: row}
                    : {selected: row},
                )
              }}
            />
          </Col>
          <Col xs={4} className="customRow">
            <div className="max-height-wrapper">
              {!this.state.selectingFromBestand && (
                <FormWerbemittel
                  va={this.props.id}
                  disabled={
                    this.state.selected.id === 0 ||
                    ability.cannot('manage', 'VA.Werbemittel')
                  }
                  selected={this.state.selected}
                  refresh={this.refresh}
                  handleDelete={this.handleDelete}
                  handleEdit={this.handleEdit}
                />
              )}
              {this.state.selectingFromBestand && (
                <FormWerbemittel
                  va={0}
                  selected={this.state.selectedBestand}
                  refresh={this.refresh}
                  selectionOnly
                  disabled
                  onCopy={this.handleCopyFromMaster}
                />
              )}
            </div>
          </Col>
        </Row>
        <Modal
          show={this.state.printing === true}
          centered
          onHide={() => this.setState({printing: false})}
        >
          <Modal.Header closeButton>
            <Modal.Title>Drucken/PDF erstellen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.printing && (
              <span>PDF der Werbemittel wird erstellt...</span>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    )
  }
  print() {
    if (this.props.thisRoute !== this.props.selectedRoute) {
      return
    }
    if (this.state.printing !== true) {
      let sortData = {
        sort: {
          [this.table.current.state.sortedCollumn]:
            this.table.current.state.sortDirection,
        },
      }

      this.props.dispatch(setPrintTrigger(false))
      this.setState({
        printing: true,
      })
      BackendService.printWerbemittel(this.props.id, sortData).then(
        response => {
          this.setState({
            printing: false,
          })
        },
      )
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.triggerPrint !== prevProps.triggerPrint) {
      this.print()
    }
  }
}

const mapStateToProps = state => {
  return {
    id: state.vaView.selectedVA,
    triggerPrint: state.navigation.printView,
    thisRoute:
      state.navigation.routes.VERANSTALTUNGEN.subRoutes.EDIT_WERBEMITTEL,
    selectedRoute: state.navigation.selectedTab,
  }
}

export default connect(mapStateToProps)(ViewWerbemittel)
