import React, { Component } from 'react';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { setNavigationRoute } from '../../actions';
import { connect } from 'react-redux';


import CheckmasterView from './CheckmasterView.js';

class CheckmasterMain extends Component {

    getRoute(key) {
        let route = this.props.selectedTab;
        for (let objkey in this.props.routes.subRoutes) {
            let newroute = this.props.routes.subRoutes[objkey]
            if (+newroute.id === +key) {
                return newroute
            }
        }
        return route
    }

    render() {

        return (
            <div className={this.props.className}>
                <Tabs activeKey={this.props.selectedTab.id}
                    onSelect={key => this.props.dispatch(setNavigationRoute(this.getRoute(key)))}>
                    <Tab eventKey={this.props.routes.subRoutes.M_EDIT_PRE.id} title="Vorbereitung">
                        <CheckmasterView listTyp={1} va_type={10} hidden={this.props.selectedTab!==this.props.routes.subRoutes.M_EDIT_PRE} />
                    </Tab>
                    <Tab eventKey={this.props.routes.subRoutes.M_EDIT_DURING.id} title="Durchführung">
                        <CheckmasterView listTyp={3} va_type={10} hidden={this.props.selectedTab!==this.props.routes.subRoutes.M_EDIT_DURING}/>
                    </Tab>
                    <Tab eventKey={this.props.routes.subRoutes.M_EDIT_POST.id} title="Nachbereitung">
                        <CheckmasterView listTyp={4} va_type={10} hidden={this.props.selectedTab!==this.props.routes.subRoutes.M_EDIT_POST}/>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

const mapStateToPropsSeries = (state) => {
    return {
        routes: state.navigation.routes.CHECKLISTEN,
        selectedTab: state.navigation.selectedTab
    }
}

export default connect(mapStateToPropsSeries)(CheckmasterMain)
