import React, {Component} from 'react'

import BackendService from '../../BackendService.js'
import _ from 'lodash'
import { createNotification } from '../../actions/index.js'

class BaseViewAusruestung extends Component {
  constructor(props) {
    super(props)
    this.refresh()

    this.state = {
      data: [],
      selected: {
        id: 0,
      },
      images: {},
      kuerzel: {},
    }

    this.handleEdit = this.handleEdit.bind(this)
    this.handleNew = this.handleNew.bind(this)
    this.handleCopy = this.handleCopy.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.refresh = this.refresh.bind(this)
    this.updateKuerzel = this.updateKuerzel.bind(this)

    this.table = React.createRef()
  }

  refresh() {
    return new Promise(resolve => {
      BackendService.getAusruestungByVA(this.props.id).then(data => {
        const size = 20
        let arrayOfData = []
        for (let index = 0; index < data.length; index += size) {
          arrayOfData.push(data.slice(index, index + size))
        }

        arrayOfData.reduce((currentPromise, elements) => {
          return currentPromise.then(() => {
            let promises = []
            for (let index = 0; index < elements.length; index++) {
              let element = elements[index]
              element.key = element.id

              if (typeof element.angelegt_von !== 'undefined') {
                this.updateKuerzel(element.angelegt_von)
              }

              for (let h = 0; h < element.previews.length; h++) {
                let preview = element.previews[h]
                if (preview.mainImage) {
                  BackendService.getAusruestungFile(preview.id).then(data => {
                    let prevImages = _.cloneDeep(this.state.images)
                    this.setDeepChild(
                      prevImages,
                      [element.key, preview.id],
                      data,
                    )
                    this.setState({
                      images: prevImages,
                    })
                  })
                  break
                }
              }
            }
            return Promise.all(promises)
          })
        }, Promise.resolve())

        let selected = this.state.selected
        if (selected.id !== 0) {
          selected = _.find(data, item => item.id === selected.id)
        }
        this.setState({
          data: data,
          selected,
        })
        resolve()
      })
    })
  }

  updateKuerzel(benutzerId) {
    if(benutzerId != 0){
      BackendService.getUser(benutzerId).then(user => {
        if (!this.state.kuerzel[user.benutzer_id]) {
          this.setState({
            kuerzel: {
              ...this.state.kuerzel,
              [user.benutzer_id]: user.kuerzel,
            },
          })
        }
      })
    }else{
      this.setState({
        kuerzel: {
          ...this.state.kuerzel,
          [0]: "-",
        },
      })
    }

  }

  setDeepChild(obj, path, value) {
    let ret = obj
    for (let index = 0; index < path.length - 1; index++) {
      if (ret[path[index]] === undefined) {
        ret[path[index]] = {}
      }
      ret = ret[path[index]]
    }
    ret[path[path.length - 1]] = value
    return ret
  }

  handleEdit(eq) {
    eq.key = eq.id
    let equipment = _.cloneDeep(this.state.data)
    let index = _.findIndex(equipment, item => item.id === eq.id)
    equipment[index] = eq
    this.setState({
      data: equipment,
    })
    this.scrollToElement(eq.id)
  }

  handleNew() {
    BackendService.createAusruestung({
      artikel: '',
      beschreibunglang: '',
      artikelnummer: '',
      lagerort: '',
      lieferant: '',
      bemerkung: '',
      veranstaltungid: this.props.id,
      ausfuerung: '',
      groesse: '',
      logo: '',
      owner: '',
      beschaffer: '',
      angelegt_von: this.props.userId,
    }).then(eq => {
      let equipment = _.cloneDeep(this.state.data)
      eq.key = eq.id
      equipment.push(eq)
      this.setState({
        data: equipment,
        selected: eq,
      })
      this.updateKuerzel(this.props.userId)
      this.table.current.setSelectedRowKey(eq.id)
      this.scrollToElement(eq.id)
    })
  }
  
  scrollToElement(id){
    const element = document.getElementById(id);
      console.log(element)
      if(element){
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }  
  }

  handleCopy() {
    let va = this.props.id
    BackendService.copyAusruestung(this.state.selected.id).then(ausruestung => {
        ausruestung.veranstaltungid = va;
        ausruestung.artikel = ausruestung.artikel + " Kopie";
        ausruestung.angelegt_von = this.props.userId;
        BackendService.editAusruestung(ausruestung).then(() => {
          this.refresh()
          this.updateKuerzel(this.props.userId)
          this.props.dispatch(
            createNotification("Ausrüstung", "Eintrag kopiert")
          );
          this.table.current.setSelectedRowKey(ausruestung.id)
        })
        
        
    })
  }

  handleDelete(id) {
    let equipment = _.cloneDeep(this.state.data)
    _.remove(equipment, item => item.id === id)
    this.setState({
      data: equipment,
      selected: {
        id: 0,
      },
    })
    this.table.current.setSelectedRowKey(0)
  }

  render() {
    return
  }
}

export default BaseViewAusruestung
