import React from "react";

import BackendService from "../../../BackendService.js";

import { connect } from "react-redux";
import { createNotification } from "../../../actions";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import CustomTable from "../../../CustomTable/ChecklistDuringTable.js";
import ChecklistForm from "./ChecklistFormDuring.js";
import CommentForm from "./CommentForm.js";

import ChecklistView from "./BaseChecklistView.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import _ from "lodash";
import moment from "moment";

import { setPrintTrigger } from "../../../actions";

import Can from "../../../config/Can";

class ChecklistViewDuring extends ChecklistView {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNew = this.handleNew.bind(this);
    this.handleChangeCheckbox2 = this.handleChangeCheckbox2.bind(this);
    this.executePrintTask = this.executePrintTask.bind(this);

    this.table = React.createRef();

    this.emptyNewElement = {
      va: this.props.id,
      list_typ: this.props.listTyp,
      bez: "",
      ort: "",
      datum: null,
      datum_bis: null,
      zu_element: undefined,
      aufgabe: "",
      aktiv: true,
      erledigt: false,
      zustaendig: { benutzer_id: "" },
    };

    this.blankElement = {
      id: 0,
      va: 0,
      list_typ: 0,
      dt_erstellt: null,
      erstellt_von: "",
      bez: "",
      ort: "",
      datum: null,
      aufgabe: "",
      erledigt_von: 0,
      aktiv: false,
      zustaendig: 0,
      erledigt: false,
    };

    this.state = {
      ...this.state,
      checklistItems: [],
      groupedChecklistItems: [],
      selectedElement: this.blankElement,
      origElement: this.blankElement,
      newElement: this.emptyNewElement,
      loading: true,
      creatingNew: false,
      bemerkungen: [],
      grouped: true,
      onlyUnchecked: false,
      includeInactive: false,
    };

    this.collumns = [
      {
        sortable: false,
        title: "sort",
        key: "sort",
        cell: (row, data, indentation) => {
          if (row.id === this.state.selectedElement.id && indentation === 0) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "transparent",
                    border: "transparent",
                    padding: "0px",
                  }}
                  onClick={() => this.sort(row, data, -1)}
                >
                  <FontAwesomeIcon icon="caret-up" size="2x" />
                </Button>

                <Button
                  style={{
                    backgroundColor: "transparent",
                    border: "transparent",
                    padding: "0px",
                  }}
                  onClick={() => this.sort(row, data, 1)}
                >
                  <FontAwesomeIcon icon="caret-down" size="2x" />
                </Button>
              </div>
            );
          }
        },
      },
      {
        sortable: false,
        title: "",
        key: "ist_aufgabe",
        cell: (row) =>
          row.ist_aufgabe ? (
            <FontAwesomeIcon key={row.id + "_icon"} icon="tasks" size="lg" />
          ) : (
            <FontAwesomeIcon
              key={row.id + "_icon"}
              icon={["far", "clock"]}
              size="lg"
            />
          ),
      },
      {
        sortable: false,
        title: "von wann",
        key: "datum",
        cell: (row) => (
          <span key={row.id + "_datum"}>
            {row.datum !== undefined
              ? moment(row.datum.substring(0, 19)).format("DD.MM.YYYY HH:mm")
              : ""}
          </span>
        ),
      },
      {
        sortable: false,
        title: "bis wann",
        key: "datum_bis",
        cell: (row) => (
          <span key={row.id + "_datum_bis"}>
            {row.datum_bis !== undefined
              ? moment(row.datum_bis.substring(0, 19)).format(
                  "DD.MM.YYYY HH:mm"
                )
              : ""}
          </span>
        ),
      },
      {
        sortable: false,
        title: "Stichwort",
        key: "bez",
        cell: (row) => <div style={{ whiteSpace: "pre-wrap" }}>{row.bez}</div>,
      },
      {
        sortable: false,
        title: "Beschreibung",
        key: "aufgabe",
        cell: (row) => (
          <div style={{ whiteSpace: "pre-wrap" }}>{row.aufgabe}</div>
        ),
      },
      {
        sortable: false,
        title: "Ort",
        key: "ort",
      },
      {
        sortable: false,
        title: "zuständig",
        key: "zustaendig",
        cell: (row) => (
          <span key={row.id + "_zustaendig"}>
            {row.zustaendig !== undefined && row.zustaendig.benutzer_id !== ""
              ? row.zustaendig.nachname + " " + row.zustaendig.kuerzel
              : "-"}
          </span>
        ),
      },
      {
        sortable: false,
        title: "aktiv",
        key: "aktiv",
        cell: (row) => (
          <input
            key={row.id + "_aktiv"}
            type="checkbox"
            disabled
            checked={row.aktiv}
          />
        ),
      },
      {
        sortable: false,
        title: "erl.",
        key: "erledigt",
        cell: (row) => (
          <input
            key={row.id + "_erledigt"}
            type="checkbox"
            disabled
            checked={row.erledigt}
          />
        ),
      },
    ];
  }

  handleSubmit(event) {
    event.preventDefault();
    let data = {
      id: this.state.selectedElement.id,
      va: this.state.selectedElement.va,
      list_typ: this.state.selectedElement.list_typ,
      dt_erstellt: this.state.selectedElement.dt_erstellt,
      erstellt_von: this.state.selectedElement.erstellt_von,
      bez: this.state.selectedElement.bez,
      ort: this.state.selectedElement.ort,
      datum:
        this.state.selectedElement.datum !== ""
          ? this.state.selectedElement.datum
          : null,
      datum_bis:
        this.state.selectedElement.datum_bis !== ""
          ? this.state.selectedElement.datum_bis
          : null,
      aufgabe: this.state.selectedElement.aufgabe,
      ist_aufgabe:
        this.state.selectedElement.ist_aufgabe === undefined
          ? false
          : this.state.selectedElement.ist_aufgabe,
      erledigt_von: this.state.selectedElement.erledigt_von,
      sort: this.state.selectedElement.sort,
      aktiv: this.state.selectedElement.aktiv,
      checkmaster_inaktiv: this.state.selectedElement.checkmaster_inaktiv,
      zustaendig:
        this.state.selectedElement.zustaendig.benutzer_id === ""
          ? null
          : this.state.selectedElement.zustaendig.benutzer_id,
      zu_element: this.state.selectedElement.zu_element,
      erledigt: this.state.selectedElement.erledigt,
      erledigt_final: this.state.selectedElement.erledigt_final,
    };
    this.setState({
      loading: true,
    });
    BackendService.editChecklistElement(data).then((response) => {
      this.refresh(() => {
        this.props.dispatch(
          createNotification("Checklisten", "Eintrag bearbeitet")
        );
      });
    });
  }

  handleNew(event) {
    event.preventDefault();
    this.setState({
      loading: true,
      creatingNew: false,
    });
    let data = {
      ...this.state.newElement,
      zustaendig:
        this.state.newElement.zustaendig.benutzer_id === ""
          ? null
          : this.state.newElement.zustaendig.benutzer_id,
      datum:
        this.state.newElement.datum !== "" ? this.state.newElement.datum : null,
      datum_bis:
        this.state.newElement.datum_bis !== ""
          ? this.state.newElement.datum_bis
          : null,
    };
    BackendService.createChecklistElement(data).then((response) => {
      this.refresh(() => {
        if (this.state.selectedElement.id !== undefined) {
          this.setState({
            selectedElement: _.find(
              this.state.checklistItems,
              (item) => item.id === response.id
            ),
          });
          document.getElementById(response.id).scrollIntoView(false);
        }
      });
    });
  }

  handleChangeCheckbox2 = (e, key) => {
    let value = e.target.checked === true ? true : false;
    this.setState({
      [key]: value,
    });
  };

  render() {
    let disableForm =
      this.state.loading ||
      this.state.selectedElement === undefined ||
      this.state.selectedElement.id === undefined;

    let userOptions = undefined;
    if (this.state.users !== undefined) {
      userOptions = [<option key={""} value={""} />];
      this.state.users.forEach((user) => {
        userOptions.push(
          <option
            key={user.benutzer.benutzer_id}
            value={user.benutzer.benutzer_id}
          >
            {user.benutzer.nachname +
              ", " +
              user.benutzer.vorname +
              " (" +
              user.benutzer.kuerzel +
              ")"}
            )
          </option>
        );
      });
    }

    let data = this.state.grouped
      ? this.state.groupedChecklistItems
      : this.state.checklistItems;
    if (!this.state.includeInactive) {
      data = _.filter(data, (row) => row.aktiv);
    }
    if (this.state.onlyUnchecked) {
      data = _.filter(data, (row) => !row.erledigt);
    }
    if (!this.state.includeInactiveCheckmaster) {
      data = _.filter(data, (row) => !row.checkmaster_inaktiv);
    }

    let collumns = _.cloneDeep(this.collumns);
    if (
      this.state.reminders !== undefined &&
      Object.keys(this.state.reminders).length > 0
    ) {
      collumns.push({
        sortable: false,
        title: "",
        key: "reminder",
        cell: (row) => (
          <span key={row.id + "_reminder"}>
            {this.state.reminders[row.id] !== undefined && (
              <FontAwesomeIcon icon="exclamation" color="yellow" size="2x" />
            )}
          </span>
        ),
      });
    }

    return (
      <Container fluid>
        <Row>
          <Col xs={8} className="customRow">
            <Row style={{ marginTop: "15px" }}>
              <Can I="create" a="VA.Durchführung">
                <Col xs="auto">
                  <Button
                    onClick={() => {
                      this.setState({
                        creatingNew: true,
                        newElement: {
                          ...this.emptyNewElement,
                          datum: this.props.vaVon,
                          datum_bis: this.props.vaBis,
                        },
                      });
                    }}
                  >
                    + Neuer Eintrag
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    onClick={() =>
                      this.setState({
                        creatingNew: true,
                        newElement: this.state.selectedElement,
                      })
                    }
                  >
                    + Eintrag kopieren
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button onClick={this.handleSaveSort}>
                    Sortierung abspeichern
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button onClick={this.removeAllReminder}>
                    Alle Reminder gelesen
                  </Button>
                </Col>
              </Can>
              <Col xs="auto" style={{ display: "flex", alignItems: "center" }}>
                <Form.Group controlId="check_group" style={{ margin: 0 }}>
                  <Form.Check
                    checked={this.state.grouped}
                    label="Gruppiert"
                    onChange={(e) => {
                      this.handleChangeCheckbox2(e, "grouped");
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs="auto" style={{ display: "flex", alignItems: "center" }}>
                <Form.Group
                  controlId="check_includeInactive"
                  style={{ margin: 0 }}
                >
                  <Form.Check
                    checked={this.state.includeInactive}
                    label="auch inaktive"
                    onChange={(e) => {
                      this.handleChangeCheckbox2(e, "includeInactive");
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="max-height-wrapper">
              <CustomTable
                ref={this.table}
                initialSortKey={"sort"}
                collumns={collumns}
                data={data}
                onSelect={(row) => {
                  this.handleSelect(row);
                }}
                includeInactive={this.state.includeInactive}
              />
            </Row>
          </Col>
          <Col xs={4} className="customRow">
            <div className="max-height-wrapper">
              <Row>
                <ChecklistForm
                  disabled={disableForm}
                  origElement={this.state.origElement}
                  userOptions={userOptions}
                  handleSubmit={this.handleSubmit}
                  handleChange={(e) => this.handleChange(e, "selectedElement")}
                  handleChangeCheckbox={(e) =>
                    this.handleChangeCheckbox(e, "selectedElement")
                  }
                  handleNewSubElement={(parentID) =>
                    this.setState({
                      creatingNew: true,
                      newElement: {
                        ...this.emptyNewElement,
                        zu_element: parentID,
                      },
                    })
                  }
                  selectedElement={this.state.selectedElement}
                  reminder={this.state.reminders[this.state.selectedElement.id]}
                  removeReminder={this.removeReminder}
                />
              </Row>
              <Container fluid style={{ paddingTop: "20px" }}>
                <Row>
                  <Col>
                    <Row>
                      <span style={{ fontWeight: "bold" }}>
                        Wichtige Bemerkungen
                      </span>
                      <Can I="create" a="VA.Durchführung">
                        <Button
                          style={{ marginLeft: "auto" }}
                          onClick={() => this.setState({ newComment: true })}
                        >
                          neue Bemerkung
                        </Button>
                      </Can>
                    </Row>
                    <Row className="height-auto">
                      <CustomTable
                        hideSearch
                        collumns={[
                          {
                            title: "von.",
                            key: "ersteller",
                          },
                          {
                            title: "Datum / Uhrzeit",
                            key: "dterstellt",
                            cell: (row) => (
                              <span key={row.id + "_datum"}>
                                {row.dterstellt !== undefined
                                  ? moment(row.dterstellt).format(
                                      "DD.MM.YYYY HH:mm"
                                    )
                                  : ""}
                              </span>
                            ),
                          },
                          {
                            title: "Bemerkung.",
                            key: "bemerkung",
                          },
                          {
                            title: "Drucken/PDF erstellen ",
                            key: "drucken",
                            cell: (row) => (
                              <input
                                key={row.id + "drucken"}
                                type="checkbox"
                                onChange={() => {
                                  BackendService.updateBemerkung(
                                    this.state.selectedElement.id,
                                    { ...row, drucken: !row.drucken }
                                  ).then((bemerkungen) => {
                                    bemerkungen.map(
                                      (bemerkung) =>
                                        (bemerkung.key = bemerkung.id)
                                    );
                                    this.setState({
                                      bemerkungen,
                                    });
                                  });
                                }}
                                checked={row.drucken}
                              />
                            ),
                          },
                        ]}
                        data={_.filter(
                          this.state.bemerkungen,
                          (entry) => entry.druckansicht === true
                        )}
                      />
                    </Row>
                    <Row>
                      <span style={{ fontWeight: "bold" }}>
                        Tracking (Nachverfolgung Änderungen)
                      </span>
                    </Row>
                    <Row className="height-auto">
                      <CustomTable
                        hideSearch
                        collumns={[
                          {
                            title: "von.",
                            key: "ersteller",
                          },
                          {
                            title: "Datum / Uhrzeit",
                            key: "dterstellt",
                            cell: (row) => (
                              <span key={row.id + "_datum"}>
                                {row.dterstellt !== undefined
                                  ? moment(row.dterstellt).format(
                                      "DD.MM.YYYY HH:mm"
                                    )
                                  : ""}
                              </span>
                            ),
                          },
                          {
                            title: "Bemerkung.",
                            key: "bemerkung",
                          },
                        ]}
                        data={_.filter(
                          this.state.bemerkungen,
                          (entry) => entry.druckansicht === false
                        )}
                      />
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>

        <Modal
          show={this.state.creatingNew}
          centered
          onHide={() => this.setState({ creatingNew: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Neues Element</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ChecklistForm
              new
              userOptions={userOptions}
              disabled={false}
              handleSubmit={this.handleNew}
              handleChange={(e) => this.handleChange(e, "newElement")}
              handleChangeCheckbox={(e) =>
                this.handleChangeCheckbox(e, "newElement")
              }
              selectedElement={this.state.newElement}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.newComment}
          centered
          onHide={() => this.setState({ newComment: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Neue Bemerkung</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.selectedElement !== undefined && (
              <CommentForm
                id={this.state.selectedElement.id}
                close={() => {
                  this.setState({
                    newComment: false,
                  });
                  BackendService.getBemerkungen(
                    this.state.selectedElement.id
                  ).then((bemerkungen) => {
                    bemerkungen.map(
                      (bemerkung) => (bemerkung.key = bemerkung.id)
                    );
                    this.setState({
                      bemerkungen,
                    });
                  });
                }}
              />
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.printTask !== undefined}
          centered
          onHide={() =>
            this.setState({ printing: false, printTask: undefined })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Drucken/PDF erstellen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.printTask !== undefined &&
              this.state.printTask.inProgress !== true && (
                <Form onSubmit={this.executePrintTask}>
                  <Form.Row>
                    <Form.Group controlId="aufgaben">
                      <Form.Check
                        type="checkbox"
                        label="mit Aufgaben"
                        value={this.state.printTask.aufgaben}
                        checked={this.state.printTask.aufgaben}
                        onChange={(e, key) => {
                          let value = e.target.checked === true ? true : false;
                          this.setState((prev) => ({
                            printTask: {
                              ...prev.printTask,
                              aufgaben: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="bemerkungen">
                      <Form.Check
                        type="checkbox"
                        label="mit Bemerkungen"
                        value={this.state.printTask.bemerkungen}
                        checked={this.state.printTask.bemerkungen}
                        onChange={(e, key) => {
                          let value = e.target.checked === true ? true : false;
                          this.setState((prev) => ({
                            printTask: {
                              ...prev.printTask,
                              bemerkungen: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="tracking">
                      <Form.Check
                        type="checkbox"
                        label="mit Tracking"
                        value={this.state.printTask.tracking}
                        checked={this.state.printTask.tracking}
                        onChange={(e, key) => {
                          let value = e.target.checked === true ? true : false;
                          this.setState((prev) => ({
                            printTask: {
                              ...prev.printTask,
                              tracking: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row style={{ justifyContent: "flex-end" }}>
                    <Button type="submit">Drucken/PDF erstellen</Button>
                  </Form.Row>
                </Form>
              )}
            {this.state.printTask !== undefined &&
              this.state.printTask.inProgress === true && (
                <span>PDF wird erstellt...</span>
              )}
          </Modal.Body>
        </Modal>
      </Container>
    );
  }

  executePrintTask(e) {
    e.preventDefault();
    BackendService.printChecklist(this.state.printTask).then(() => {
      this.setState({
        printing: false,
        printTask: undefined,
      });
    });
    this.setState({
      printTask: {
        inProgress: true,
      },
    });
  }

  print() {
    if (this.props.hidden === true) {
      return;
    }
    if (this.state.printing !== true) {
      this.props.dispatch(setPrintTrigger(false));
      this.setState({
        printing: true,
        printTask: {
          va: this.props.id,
          listTyp: this.props.listTyp,
          unerledigt: this.state.onlyUnchecked,
          inaktiv: this.state.includeInactive,
          inaktivMaster:
            this.state.includeInactiveCheckmaster === undefined
              ? false
              : this.state.includeInactiveCheckmaster,
          bemerkungen: 0,
          tracking: 0,
          aufgaben: 1,
          grupiert: this.state.grouped,
          sort: { datum: "asc", sort: "asc" },
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.triggerPrint !== prevProps.triggerPrint) {
      this.print();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    id: state.vaView.selectedVA,
    vaVon: state.vaView.selectedVAObj.von,
    vaBis: state.vaView.selectedVAObj.bis,
    user: state.userData.user,
    rights: state.userData.rights,
    triggerPrint: state.navigation.printView,
  };
};

export default connect(mapStateToProps)(ChecklistViewDuring);
