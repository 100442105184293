import React, { Component } from "react";

import BackendService from "../../../BackendService.js";

import { connect } from "react-redux";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import CustomTable from "../../../CustomTable/CustomTable.js";

import BeteiligteForm from "./BeteiligteForm";

import { setPrintTrigger } from "../../../actions";

import _ from "lodash";
import NewBeteiligterForm from "./NewBeteiligterForm.js";
import { createNotification } from "../../../actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ability } from "../../../config/ability";
import Can from "../../../config/Can";

class ViewBeteiligte extends Component {
  constructor(props) {
    super(props);
    this.refresh();

    this.state = {
      data: [],
      highestSort: 0,
      printing: false,
      saving: false,
      selected: {
        id: 0,
      },
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleNew = this.handleNew.bind(this);
    this.handleNewSubmit = this.handleNewSubmit.bind(this);
    this.handleNewCancel = this.handleNewCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.refresh = this.refresh.bind(this);

    this.editForm = React.createRef();
    this.newForm = React.createRef();
    this.table = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.triggerPrint !== prevProps.triggerPrint) {
      this.print();
    }
  }

  refresh() {
    BackendService.getBeteiligte(this.props.id).then((beteiligte) => {
      
      let data = beteiligte.map(
        (beteiligter) =>
          (beteiligter = {
            ...beteiligter,
            key: beteiligter.id,
          })
      );
   
      let highestSort = 0;
      let toSort = [];
      
      data.forEach((row) => {
        if (row.sort === undefined) {
          toSort.push(row);
        } else {
          if (row.sort > highestSort) {
            highestSort = row.sort;
          }
        }
      });
      toSort.forEach((row) => {
        highestSort++;
        row.sort = highestSort;
      });

      let selected = this.state.selected
      
      if (selected.id !== 0) {
        selected = _.find(data, item => item.id === selected.id)
      }

      this.setState({
        data,
        highestSort,
        selected
      });

    });
    
  }

  handleEdit(beteiligter) {
    let beteiligte = _.cloneDeep(this.state.data);
    let index = _.findIndex(beteiligte, (item) => item.id === beteiligter.id);

    // Setzen des Keys für die Tabelle
    beteiligter.key = beteiligter.id;
    beteiligte[index] = beteiligter;

    this.setState({
      data: beteiligte,
    });

    this.scrollToElement(beteiligter.key);
  }

  handleNew() {
    BackendService.createBeteiligter(
      this.props.id,
      {
        namefirma: "",
        anschrift: "",
        telefon: "",
        mobil: "",
        fax: "",
        www: "",
        email: "",
        bemerkungen: "",
        va: this.props.id,
        funktion: "",
        erstellt_von: "",
        dt_erstellt: null,
      }
    ).then((newBeteiligter) => {
      let beteiligte = _.cloneDeep(this.state.data);
      newBeteiligter.key = newBeteiligter.id;
      beteiligte.push(newBeteiligter);
      this.setState({
        data: beteiligte,
        selected: newBeteiligter,
        highestSort: newBeteiligter.sort,
        creatingNew: true,
      });
    });
  }

  handleNewSubmit(){
    this.table.current.setSelectedRowKey(this.state.selected.id);
    this.scrollToElement(this.state.selected.id);
    this.props.dispatch(
      createNotification("Beteiligte", "Beteiligter hinzugefügt")
    );
    this.saveSort();

    this.setState({
      creatingNew: false,
    });
    
  }

  handleNewCancel(){
    BackendService.deleteBeteiligter(this.state.selected).then((data) => {
      this.handleDelete();
    });

    this.setState({
      creatingNew: false,
    });
  }

  scrollToElement(id){
    const element = document.getElementById(id);
      if(element){
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }  
  }

  handleDelete() {
    this.refresh();
    this.setState({
      highestSort: 0,
      selected: {
        id: 0,
      },
    });
    this.table.current.setSelectedRowKey(0);
  }

  sort(row, data, direction) {
    return new Promise((resolve) => {
      let index = _.indexOf(data, row)
      let other = data[index + direction]
      let tmpSort = other.sort
      let newData = _.cloneDeep(data)
      newData[index + direction].sort = row.sort
      newData[index].sort = tmpSort

      this.setState({
        data: newData,
         selected: newData[index],
      })
      resolve()
    })
  }

  saveSort() {
    this.setState({saving: true})
    let body = {
      beteiligte: this.table.current.getSortedIds(),
    }
    BackendService.sortBeteiligte(this.props.id, body).then(returnValue =>
      this.setState({saving: false})
    )
  }

  handleSort(row, data, direction){
    this.sort(row, data, direction).then(() => {
      this.saveSort()
    })
  }

  render() {
    return (
      <Container fluid>
        <Row style={{ marginTop: "15px" }}>
          <Can I="create" a="Beteiligte">
            <Col xs="auto">
              <Button onClick={this.handleNew}>Neuer Eintrag</Button>
            </Col>
          </Can>
        </Row>
        <Row className="max-height-wrapper">
          <Col xs={8} className="customRow">
            <CustomTable
              ref={this.table}
              initialSortKey={"sort"}
              collumns={[
                {
                  title: "sort",
                  key: "sort",
                  cell: (row, data) => {
                    if (
                      row.id === this.state.selected.id &&
                      this.table.current.state.sortedCollumn === "sort"
                    ) {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            style={{
                              backgroundColor: "transparent",
                              border: "transparent",
                              padding: "0px",
                            }}
                            disabled={this.state.saving}
                            onClick={(e) => {
                              //e.preventDefault();
                              //e.stopPropagation();
                              this.handleSort(row, data, -1);
                      
                            }}
                          >
                            <FontAwesomeIcon icon="caret-up" size="2x" />
                          </Button>

                          <Button
                            style={{
                              backgroundColor: "transparent",
                              border: "transparent",
                              padding: "0px",
                            }}
                            disabled={this.state.saving}
                            onClick={(e) => {
                              //e.preventDefault();
                              //e.stopPropagation();
                              this.handleSort(row, data, 1);
                              
                            }}
                          >
                            <FontAwesomeIcon icon="caret-down" size="2x" />
                          </Button>
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "Funktion",
                  key: "funktion",
                },
                {
                  title: "Vor- und Nachname / Firma",
                  key: "namefirma",
                },
                {
                  title: "Anschrift",
                  key: "anschrift",
                },
              ]}
              data={this.state.data}
              onSelect={(row) => {
                this.setState({ selected: row });
              }}
            />
          </Col>
          <Col xs={4} className="customRow">
            <Row className="max-height-wrapper-2">
              <BeteiligteForm
                disabled={
                  this.state.selected.id === 0 ||
                  (this.state.selected.id !== 0 && !ability.can("edit", "VA"))
                }
                selected={this.state.selected}
                ref={this.editForm}
                onSubmit={this.handleEdit}
                onDelete={this.handleDelete}
                refresh={this.refresh}
              />
            </Row>
          </Col>
        </Row>
        <Modal
          size="xl"
          show={this.state.creatingNew}
          centered
          onHide={this.handleNewCancel}
        >
          <Modal.Header closeButton>
            <Modal.Title>Neuer Eintrag</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NewBeteiligterForm
              va={this.props.id}
              selectedBeteiligter={this.state.selected}
              highestSort={this.state.highestSort}
              handleNewSave={this.handleNewSubmit}
              refresh={this.refresh}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.printing === true}
          centered
          onHide={this.handleNewCancel}
        >
          <Modal.Header closeButton>
            <Modal.Title>Drucken/PDF erstellen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.printing && (
              <span>PDF der Beteiligten wird erstellt...</span>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
  print() {
    if (this.props.thisRoute !== this.props.selectedRoute) {
      return;
    }
    if (this.state.printing !== true) {
      let sortData = {
        sort: {
          [this.table.current.state.sortedCollumn]:
            this.table.current.state.sortDirection,
        },
      };

      this.props.dispatch(setPrintTrigger(false));
      this.setState({
        printing: true,
      });
      BackendService.printBeteiligte(this.props.id, sortData).then(response => {
        this.setState({
          printing: false,
        })
      })
    }
  }
}

const mapStateToProps = (state) => {
  return {
    id: state.vaView.selectedVA,
    triggerPrint: state.navigation.printView,
    thisRoute:
      state.navigation.routes.VERANSTALTUNGEN.subRoutes.EDIT_BETEILIGTE,
    selectedRoute: state.navigation.selectedTab,
  }
}

export default connect(mapStateToProps)(ViewBeteiligte)
