import React, {Component} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PDF1 from '../../Docs/Bedienungsanleitung.pdf'

class ViewHome extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <Container fluid>
          <Row>
            <Col>
              <Row>
                <img src="/imgs/willkommen.png" alt="" />
              </Row>
              <Row>
                <Col xs={6}>
                  <Row>
                    <p style={{marginRight: '20px'}}>
                      Herzlich Willkommen bei steve - unserer gemeinsamen
                      Planungs-Plattform für alle STARCLUB-Veranstaltungen. Mit
                      steve werden wir künftig unsere STARCLUB-Events noch
                      effizienter und für alle zeitsparender auf die Beine
                      stellen. Viel Erfolg bei der Arbeit mit steve. <br />
                      <br /> Ihr Team für den Buderus STARCLUB
                    </p>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <a href={PDF1} target="_blank" rel="noopener noreferrer">
                      <img
                        src="/imgs/dasErsteMal.jpg"
                        alt="Das erste Mal hier?"
                      />
                    </a>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <Row>
                    <img
                      src="/imgs/signatures/petersen.gif"
                      alt="Martin Petersen"
                    />
                  </Row>
                  <Row>
                    <label>Martin Petersen</label>
                  </Row>
                </Col>
                <Col xs={3}>
                  <Row>
                    <img
                      src="/imgs/signatures/groebe.gif"
                      alt="Claudia Gröbe"
                    />
                  </Row>
                  <Row>
                    <label>Claudia Gröbe</label>
                  </Row>
                </Col>
                <Col xs={3}>
                  <Row>
                    <img
                      src="/imgs/signatures/schubert.gif"
                      alt="Kirsten Schubert"
                    />
                  </Row>
                  <Row>
                    <label>Kirsten Schubert</label>
                  </Row>
                </Col>
                <Col xs={3}>
                  <Row>
                    <img
                      src="/imgs/signatures/becker.jpg"
                      alt="Michael Becker"
                    />
                  </Row>
                  <Row>
                    <label>Michael Becker</label>
                  </Row>
                </Col>
                <Col xs={3}>
                  <Row>
                    <img
                      src="/imgs/signatures/weishaupt.jpg"
                      alt="Jeanette Weishaupt"
                    />
                  </Row>
                  <Row>
                    <label>Jeanette Weishaupt</label>
                  </Row>
                </Col>
                <Col xs={3}>
                  <Row>
                    <img
                      src="/imgs/signatures/gorschlueter.jpg"
                      alt="Laura Gorschlüter"
                    />
                  </Row>
                  <Row>
                    <label>Laura Gorschlüter</label>
                  </Row>
                </Col>
                <Col xs={3}>
                  <Row>
                    <img
                      src="/imgs/signatures/tina_sauerwald.jpg"
                      alt="Tina Sauerwald"
                    />
                  </Row>
                  <Row>
                    <label>Tina Sauerwald</label>
                  </Row>
                </Col>
                <Col xs={3}>
                  <Row>
                    <img
                      src="/imgs/signatures/tatjanaSchaub.jpg"
                      alt="Tatjana Schaub"
                    />
                  </Row>
                  <Row>
                    <label>Tatjana Schaub</label>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default ViewHome
