import React, {Component} from 'react'
import {connect} from 'react-redux'
import {setNavigationRoute, setPrintTrigger, setVaSelected} from './actions'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Help from './Help'
import FAQ from './FAQ'
import Feedback from './Feedback'
import _ from 'lodash'

class Navigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showHelp: false,
    }

    this.setRouteFromURL = this.setRouteFromURL.bind(this)
    this.setRouteFromURL()
  }

  setRouteFromURL() {
    let urlRoute = this.matchRoute(this.props.pathname, this.props.routes)

    if (urlRoute !== undefined) {
      if (urlRoute.containsVA === true) {
        let va = this.props.pathname.match(/\d+/)[0]
        this.props.dispatch(setVaSelected({id: va}))
        this.props.dispatch(setNavigationRoute(urlRoute, va))
      } else {
        this.props.dispatch(setNavigationRoute(urlRoute))
      }
    }
  }

  render() {
    let showPrint = this.props.selectedTab.print === true

    return (
      <Container
        style={{
          paddingLeft: '20px',
          paddingRight: '20px',
          verticalAlign: 'center',
        }}
        fluid
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
          className="navigation"
        >
          <div>
            <Button
              onClick={() => {
                this.props.dispatch(setNavigationRoute(this.props.routes.HOME))
              }}
            >
              Home
            </Button>
            {this.props.rights[5] !== undefined && (
              <Button
                onClick={() => {
                  this.props.dispatch(
                    setNavigationRoute(this.props.routes.VERANSTALTUNGEN),
                  )
                }}
              >
                Veranstaltungen
              </Button>
            )}
            {this.props.rights[4] !== undefined && (
              <Button
                onClick={() => {
                  this.props.dispatch(
                    setNavigationRoute(this.props.routes.BENUTZER),
                  )
                }}
              >
                Benutzer
              </Button>
            )}
            {this.props.rights[8] !== undefined && (
              <Button
                onClick={() => {
                  this.props.dispatch(
                    setNavigationRoute(
                      this.props.routes.CHECKLISTEN.subRoutes.M_EDIT_PRE,
                    ),
                  )
                }}
              >
                Checklisten
              </Button>
            )}
            {this.props.rights[9] !== undefined && (
              <Button
                onClick={() => {
                  this.props.dispatch(
                    setNavigationRoute(this.props.routes.TODO),
                  )
                }}
              >
                ToDos
              </Button>
            )}
            {this.props.rights[18] !== undefined && (
              <Button
                onClick={() => {
                  this.props.dispatch(
                    setNavigationRoute(this.props.routes.WERBEMITTEL),
                  )
                }}
              >
                Werbemittel/Präsente/Material
              </Button>
            )}
            {this.props.rights[20] !== undefined && (
              <Button
                onClick={() => {
                  this.props.dispatch(
                    setNavigationRoute(this.props.routes.AUSRUESTUNG),
                  )
                }}
              >
                Betreuerausrüstung verwalten
              </Button>
            )}
          </div>
          <div>
            <Button
              style={{}}
              onClick={() => this.setState({showFeedback: true})}
            >
              <FontAwesomeIcon icon="envelope-open-text" size="lg" />
              <span style={{paddingLeft: '10px'}}>Feedback</span>
            </Button>

            <OverlayTrigger overlay={<Tooltip>FAQ</Tooltip>}>
              <Button style={{}} onClick={() => this.setState({showFAQ: true})}>
                <FontAwesomeIcon icon="lightbulb" size="lg" />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger overlay={<Tooltip>Hilfe</Tooltip>}>
              <Button
                style={{}}
                onClick={() => this.setState({showHelp: true})}
              >
                <FontAwesomeIcon icon="question-circle" size="lg" />
              </Button>
            </OverlayTrigger>

            {showPrint && (
              <OverlayTrigger
                overlay={<Tooltip>Drucken/PDF erstellen</Tooltip>}
              >
                <Button
                  style={{}}
                  onClick={() => {
                    this.props.dispatch(setPrintTrigger(true))
                  }}
                >
                  <FontAwesomeIcon icon="print" size="lg" />
                </Button>
              </OverlayTrigger>
            )}
          </div>
        </div>

        <Modal
          show={this.state.showHelp}
          centered
          onHide={() => this.setState({showHelp: false})}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Hilfe</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Help />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showFAQ}
          centered
          onHide={() => this.setState({showFAQ: false})}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>FAQ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FAQ />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showFeedback}
          centered
          onHide={() => this.setState({showFeedback: false})}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Feedback />
          </Modal.Body>
        </Modal>
      </Container>
    )
  }

  matchRoute(searchRoute, availableRoutes) {
    let returnValue = undefined
    _.forEach(
      availableRoutes,
      function (route, key) {
        let testRegEx = new RegExp(route.regex, 'i')
        if (searchRoute.match(testRegEx) !== null) {
          // return early if subroute found
          if (returnValue !== undefined && returnValue.name !== undefined) {
            return
          }

          let subRoute = this.matchRoute(searchRoute, route.subRoutes)
          returnValue = subRoute !== undefined ? subRoute : route
        }
      }.bind(this),
    )

    return returnValue
  }

  componentDidUpdate(prevProps) {
    if (this.props.pathname !== prevProps.pathname) {
      this.setRouteFromURL()
    }
  }
}

const mapStateToProps = state => {
  return {
    routes: state.navigation.routes,
    selectedTab: state.navigation.selectedTab,
    rights: state.userData.rights,

    pathname: state.router.location.pathname,
  }
}

export default connect(mapStateToProps)(Navigation)
