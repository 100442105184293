import React from 'react';

import {isSunOrHoliday} from 'feiertagejs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomTable from './CustomTable'

class ChecklistDuringTable extends CustomTable {

    constructor(props){
        super(props)
        this.lastDate = ''
    }

    createRow(row, selectionStyle, indentation, data) {
        indentation = indentation === undefined ? 0 : indentation;
        let rowStyle={}
        let date=row.datum === undefined ? '' : row.datum.substring(0,10)
        let dateAsDate = isNaN(Date.parse(row.datum)) ? undefined : new Date(row.datum)
        let rulerRow = false
        
        if (indentation === 0 && this.lastDate!==date) {
            rowStyle={borderBlockStart: '2px solid'}
            this.lastDate=date
            rulerRow = true;
        }

        if (dateAsDate!==undefined && isSunOrHoliday(dateAsDate,'ALL')) {
            rowStyle={...rowStyle, color: 'red'}
        }

        let bodyContent = []
        let i = 0
        let minOneSub = this.props.includeInactive
        if (row.subElemnts !== undefined && !this.props.includeInactive) {
            minOneSub = row.subElemnts.find(el => el.aktiv) || false
        }
        this.props.collumns.forEach(collumn => {
            let content = []
            let contentstyle ={}
            if (i === 1) {
                let space = 25*indentation;
                contentstyle = {paddingLeft:space+"px", display: 'flex', alignItems: 'center', border: 'transparent'}
                if (row.subElemnts !== undefined) {
                    if (minOneSub) {
                        let icon = this.state.groupToggles[row.id] === true ? <FontAwesomeIcon icon="caret-down" size='lg'/> : <FontAwesomeIcon icon="caret-right" size='lg'/>
                        content.push(<span key='groupToggle' onClick={()=>this.toggleRow(row.id)} style={{padding: "4px"}}>{icon}</span>)
                    }
                }
            }
            content.push(collumn.cell === undefined ? row[collumn.key] : collumn.cell(row, data, indentation))
            bodyContent.push(<td style={contentstyle} key={row.key + '_' + collumn.key}>{content}</td>)
            i++
        })
        const ref = React.createRef();
        let element =
            [<tr
                key={row.key}
                id={row.id}
                onClick={(e) => this.onSelect(row, ref)}
                ref={ref}
                style={row.key === this.state.selectedRowKey ? {...selectionStyle, ...rowStyle} : rowStyle}>
                {bodyContent}
            </tr>]

        if(rulerRow) {
            const originDate = new Date(this.lastDate);
            const formattedDate = originDate.toLocaleDateString('de-DE',{weekday: 'long',year:"numeric",month:"2-digit", day:"2-digit"})
            element = [
                <tr><th colSpan={i} style={{...rowStyle, ...{textAlign: 'center' }}}>{formattedDate}</th></tr>
                ,
                ...element
            ]
        }

        if (row.subElemnts !== undefined) {
            if (this.state.groupToggles[row.id] === true) {
                row.subElemnts.forEach(subElement => {
                    if (subElement.aktiv || this.props.includeInactive) {
                        element.push.apply(element, this.createRow(subElement, selectionStyle, indentation + 1))
                    }
                });
            }
        }

        return element;
    }
}

export default ChecklistDuringTable
