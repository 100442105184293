import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from './actions';


class UserStatusBox extends Component {
    render() {
        return (
            <div style={this.props.style} className={this.props.className}>
                    <span style={{paddingRight: '5px'}}>{this.props.user}:</span>
                    <span style={{color: 'white'}}onClick={() => {this.logout()}}>logout</span>
            </div>
        )
    }

    logout(){
        this.props.dispatch(logout())
    }
}

const mapStateToPropsSeries = (state) => {
    return {
        user: state.userData.user.benutzername
    }
}

export default connect(mapStateToPropsSeries)(UserStatusBox)