import React, { Component } from 'react';
import { connect } from 'react-redux';
import BackendService from './BackendService.js';
import { setUserData, setVaSelected } from './actions';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { setNavigationRoute } from './actions';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: undefined,
        };

        this.props.dispatch(setNavigationRoute(this.props.routes.HOME))
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(loginData) {
        BackendService.authenticate(loginData.username, loginData.password)
            .then(
                response => {
                    let data = response.data
                    // Set Selected va to 0 to avoid 403 from axios va request
                    this.props.dispatch(setVaSelected({id: 0}))
                    this.props.dispatch(setUserData({ data }))
                })
            .catch(error => {
                console.log(error.response)
                this.setState({ error: error, errorData: error.response.data })
            })
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ error: undefined, errorData: undefined })
        this.onSubmit(this.state)
    }

    render() {
        return (
            <Container>
                <Row style={{ justifyContent: 'center' }}>
                    <Col sm={9} md={7} lg={5} mx='auto' style={{ backgroundColor: 'white', borderRadius: '25px', paddingTop: '20px', paddingLeft: '40px', paddingRight: '40px', paddingBottom: '40px' }}>
                        <Row style={{ justifyContent: 'center' }}>
                            <h1>Login</h1>
                        </Row>
                        {this.state.error !== undefined &&
                            <Row style={{ justifyContent: 'center' }}>

                            </Row>
                        }
                        <Row>
                            <Col>
                                <Form onSubmit={e => this.handleSubmit(e)}>
                                    <Form.Row >
                                        <Form.Group controlId='username' style={{ flexGrow: 1 }}>
                                            <Form.Control type="text" placeholder="Nutzername" onChange={e => this.setState({ username: e.target.value })} isInvalid={this.state.error !== undefined} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group controlId='password' style={{ flexGrow: 1 }}>
                                            <Form.Control type="password" placeholder="Passwort" onChange={e => this.setState({ password: e.target.value })} isInvalid={this.state.error !== undefined} />
                                            <Form.Control.Feedback type="invalid">
                                                {this.state.errorData}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row style={{ flexGrow: 1 }}>
                                        <Button style={{ width: '100%' }} type="submit">Einloggen</Button>
                                    </Form.Row>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToPropsSeries = (state) => {
    return {
        routes: state.navigation.routes,
    }
}

export default connect(mapStateToPropsSeries)(Login)
