import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import rootReducer from '../reducers'

import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from '../reducers'
import { ability, defineAbilitiesFor,  defineVaAbilities } from '../config/ability'

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk,
      ),
    ),
  )

    let currentUser, currentVa;

    const veranstaltungsRouteExp = /\/Veranstaltungen\/\d+\/\w+/;

    store.subscribe(() => {
        const prevUser = currentUser;
        const prevVa = currentVa;
        currentUser = store.getState().userData;
        currentVa = store.getState().vaView.selectedVAObj;
        const currentRoute = store.getState().router.location.pathname

        if (prevVa !== currentVa  && currentVa.typ !== undefined) {
            ability.update(defineVaAbilities(currentUser,currentVa))
        } 

        if(prevUser !== currentUser || (!veranstaltungsRouteExp.test(currentRoute) && currentVa.typ === undefined) ) {
            ability.update(defineAbilitiesFor(currentUser))
        }

    })

  return store
}

//export default createStore(rootReducer, applyMiddleware(thunk));
