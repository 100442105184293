import React, { Component } from 'react';
import UserStatusBox from './UserStatusBox.js'
import styles from './SteveHeader.module.css'

export default class SteveHeader extends Component {
    render() {
        return (
            <div className={styles.header}>
                <img src='/imgs/steve-logo-transparent.png' className={styles.logo} alt='STEVE Logo'/>
                <UserStatusBox className={`${styles.logout} ${styles.right}`}/>
                <img src='/imgs/starclub-logo.png' className={`${styles.logo} `} style={{height: "80%"}} alt='Buderus SC Logo'/>
            </div>
        )
    }
}

