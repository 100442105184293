import {Component} from 'react'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

import _ from 'lodash'

class BaseForm extends Component {
  constructor(...args) {
    super(...args)
    this.default = {
      selected: {
        id: 0,
      },
    }
  }

  handleChange = e => {
    let key = e.target.id
    let value = e.target.value

    console.log(key, value)

    this.modifySelected(key, value)
  }

  handleChangeCheckbox = e => {
    let key = e.target.id
    let value = e.target.checked === true ? true : false
    this.modifySelected(key, value)
  }

  modifySelected = (key, value) => {
    let keys = key.split('.')
    let prevSelected = _.cloneDeep(this.state.selected)
    this.setDeepChild(prevSelected, keys, value)
    this.setState(prevState => ({
      selected: prevSelected,
    }))
  }

  setDeepChild(obj, path, value) {
    let ret = obj
    for (let index = 0; index < path.length - 1; index++) {
      if (ret[path[index]] === undefined) {
        ret[path[index]] = {}
      }
      ret = ret[path[index]]
    }
    ret[path[path.length - 1]] = value
    return ret
  }

  reset() {
    this.setState({
      selected: {
        ...this.default.selected,
        ...this.props.selected,
      },
    })
  }

  resetPreviews() {
    this.setState({
      selected: {
        ...this.state.selected,
        previews: this.props.selected.previews,
      },
    })
  }

  render() {
    return
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.selected, prevProps.selected)) {
      if (
        !isEqual(
          omit(this.props.selected, ['previews', 'key']),
          omit(prevProps.selected, ['previews', 'key']),
        )
      ) {
        this.reset()
      } else {
        this.resetPreviews()
      }
    }
  }
}

export default BaseForm
