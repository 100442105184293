import React, { Component, createRef } from "react";
import Datetime from "react-datetime";
import moment, { isMoment } from "moment";
import localeDE from "moment/locale/de";

class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: this.getMoment(props.value, "date"),
      time: this.getMoment(props.value, "time"),
    };

    this.calendarRef = createRef();
    this.timeRef = createRef();

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }

  componentDidMount() {
    document
      .getElementsByClassName(`datePickerInput-${this.props.id}`)[0]
      .addEventListener("keydown", this.onKeyDown);
    if (!this.props.withoutTime) {
      document
        .getElementsByClassName(`timePickerInput-${this.props.id}`)[0]
        .addEventListener("keydown", this.onKeyDown);
    }
  }

  componentWillUnmount() {
    document
      .getElementsByClassName(`datePickerInput-${this.props.id}`)[0]
      .removeEventListener("keydown", this.onKeyDown);
    if (!this.props.withoutTime) {
      document
        .getElementsByClassName(`timePickerInput-${this.props.id}`)[0]
        .removeEventListener("keydown", this.onKeyDown);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      if (this.props.value !== this.state.value) {
        this.setState({
          date: this.getMoment(this.props.value, "date"),
          time: this.getMoment(this.props.value, "time"),
        });
      }
    }
  }

  onChange(value, type) {
    this.setState({
      [type]: value,
    });
  }

  onBlur() {
    this.changeValue();
  }

  onFocus(type) {
    if (type === "date") {
      document
        .getElementsByClassName(`datePickerInput-${this.props.id}`)[0]
        .select();
    } else if (type === "time") {
      document
        .getElementsByClassName(`timePickerInput-${this.props.id}`)[0]
        .select();
    }
  }

  onKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();

      e.target.blur();
      this.calendarRef.current.closeCalendar();
      this.timeRef.current.closeCalendar();
      this.changeValue();
    }
  }

  changeValue() {
    let val = null;
    let date = null;
    let time = null;
    if (isNaN(Date.parse(this.state.date))) {
      date = moment(this.state.date, "LLL", "de");
    } else {
      date = this.state.date;
    }
    if (isNaN(Date.parse(this.state.time))) {
      time = moment(this.state.time, "LLL", "de");
    } else {
      time = this.state.time;
    }

    if (!this.props.withoutTime && isMoment(date) && date.isValid() && isMoment(time) && time.isValid()) {
      val = moment(date.format("MM/DD/YYYY") + " " + time.format("HH:mm"));
    }else if(isMoment(date) && date.isValid()) {
      val = moment(date.format("MM/DD/YYYY"));
    }

    if (isMoment(val)) {
      this.props.changeValue({
        target: {
          id: this.props.valueId,
          value: this.props.withoutTime
            ? val.format("MM/DD/YYYY")
            : val.format("MM/DD/YYYY HH:mm"),
        },
      });
    }
  }

  getMoment(value, type) {
    if (value) {
      if (moment.isMoment(value)) {
        return value;
      } else {
        const dateFormat = this.props.dateFormat || "DD.MM.YYYY";
        const timeFormat = this.props.timeFormat || "HH:mm";

        switch (type) {
          case "date":
            return moment(value, `${dateFormat}`);
          case "time":
            return moment(value, `${timeFormat}`);
          default:
            return this.props.withoutTime
              ? moment(value, `${dateFormat}`)
              : moment(value, `${dateFormat} ${timeFormat}`);
        }
      }
    }
    return value;
  }

  getStringFromMoment(value) {
    const dateFormat = this.props.dateFormat || "DD.MM.YYYY";
    const timeFormat = this.props.timeFormat || "HH:mm";
    return value.format(`${dateFormat} ${timeFormat}`);
  }

  render() {
    return (
      <>
        <Datetime
          id={`datePickerInput-${this.props.id}`}
          ref={this.calendarRef}
          locale={moment.locale()}
          dateFormat={this.props.dateFormat || "DD.MM.YYYY"}
          timeFormat={false}
          value={this.state.date}
          disabled={this.props.disabled}
          onBlur={this.onBlur}
          onChange={(value) => {
            this.onChange(value, "date")
          }}
          onFocus={() => this.onFocus("date")}
          inputProps={{
            className: `form-control datePickerInput-${this.props.id}`,
          }}
        />
        {!this.props.withoutTime && (
          <Datetime
            id={`timePickerInput-${this.props.id}`}
            ref={this.timeRef}
            locale={moment.locale()}
            dateFormat={false}
            timeFormat={this.props.timeFormat || "HH:mm"}
            value={this.state.time}
            disabled={this.props.disabled}
            onBlur={this.onBlur}
            onChange={(value) => this.onChange(value, "time")}
            onFocus={() => this.onFocus("time")}
            inputProps={{
              className: `form-control timePickerInput-${this.props.id}`,
            }}
          />
        )}
      </>
    );
  }
}

export default DatePicker;
