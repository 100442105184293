import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import BackendService from '../BackendService';
import _ from 'lodash';
import moment from 'moment'
import CustomTable from '../CustomTable/CustomTable'
import { connect } from 'react-redux';
import { setPrintTrigger } from '../actions';
import Spinner from 'react-bootstrap/esm/Spinner';



class Todo extends Component {

    constructor(props) {
        super(props)

        this.state = {
            todos: [],
            bemerkungen: {},
            loading: true,
            selectedVA: '0',
            includeOpen: true,
            includeClosed: false,
            erledigt: false,
            offen: true,
        }

        this.listTypes = {
            1: 'Vorbereitung',
            3: 'Durchführung',
            4: 'Nachbereitung'
        }

        this.table = React.createRef()

        BackendService.getTodos().then(todosAndVAs => {

            let todos = todosAndVAs.todos
            let VAs = todosAndVAs.VAs
            todos.map(todo => {
                todo.key = todo.id
                return todo
            })
            this.setState({
                todos: todos,
                VAs,
                VAs_mapped: _.keyBy(VAs, 'id'),
                loading: false
            })

            // todos.forEach(todo => {
            //     let todoID = todo.id
            //     BackendService.getBemerkungen(todo.id).then(bemerkungen => {
            //         let bemerkungState = _.cloneDeep(this.state.bemerkungen)
            //         bemerkungState[todoID] = bemerkungen
            //         this.setState({
            //             bemerkungen: bemerkungState,
            //             VAs,
            //             VAs_mapped: _.keyBy(VAs, 'id'),
            //             loading: false
            //         })
            //     })
            // })
        })

    }

    setComplete(entry) {
        let data = _.cloneDeep(this.state.todos)
        data[_.findIndex(data, todo => todo.id === entry.id)].erledigt = !entry.erledigt
        this.setState({
            todos: data
        })
        let sendData = {
            ...entry,
            zustaendig: entry.zustaendig.benutzer_id,
            erledigt: !entry.erledigt
        }
        BackendService.editChecklistElement(sendData).then(response => { })
    }

    renderBemerkungen(array) {
        let returnValue = []

        array.forEach(element => {
            if (element.druckansicht === true) {
                returnValue.push(
                    <Row>
                        <span>{element.bemerkung}</span>
                    </Row>
                )
            }
        });

        return returnValue
    }

    render() {


        if (this.state.loading === true) {
            return (
                <div className={this.props.className} style={{display: "flex", flexDirection: "row", gap: "15px"}}>
                    <Spinner animation="border" />
                    <p>ToDos werden geladen...</p>   
                </div>)
        }

        let VAOptions = [<option key='0' value='0'>Alle</option>]
        this.state.VAs.forEach(va => {
            VAOptions.push(<option value={va.id} key={va.id}>{va.bez}</option>)
        });

        let data = _.cloneDeep(this.state.todos)
        if (this.state.includeOpen === false) {
            _.remove(data, (element) => element.erledigt === false)
        }
        if (this.state.includeClosed === false) {
            _.remove(data, (element) => element.erledigt === true)
        }
        if (this.state.selectedVA !== '0') {
            data = _.filter(data, (clElement) => +clElement.va === +this.state.selectedVA)
        }

        return (
            <div className={this.props.className}>
                <Container fluid>
                    <Row key='filter'>
                        <Form>
                            <Form.Row>
                                <Col xs="auto" style={{ paddingLeft: '20px' }}>
                                    <Form.Group as={Row} controlId="selectedVA">
                                        <Form.Label>
                                            Veranstaltungstyp:
                                        </Form.Label>
                                        <Col>
                                            <Form.Control
                                                as="select" value={this.state.selectedVA}
                                                onChange={(e) => { this.setState({ selectedVA: e.target.value }) }}
                                            >
                                                {VAOptions}
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="open" style={{ margin: 0 }}>
                                        <Form.Check
                                            checked={this.state.includeOpen}
                                            label="offen"
                                            onChange={(e) => { this.setState({ includeOpen: e.target.checked === true ? true : false }) }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="closed" style={{ margin: 0 }}>
                                        <Form.Check
                                            checked={this.state.includeClosed}
                                            label="erledigt"
                                            onChange={(e) => { this.setState({ includeClosed: e.target.checked === true ? true : false }) }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        </Form>
                    </Row>
                    <Row key='Table'>
                        <CustomTable
                            ref={this.table}
                            title="Todos"
                            initialSortKey={'datum'}

                            collumns={[
                                {
                                    title: 'Veranstaltung',
                                    key: 'va_name',
                                    sortable: true
                                },
                                {
                                    title: 'Liste',
                                    key: 'list_typ',
                                    cell: row =>
                                        <span key={row.id + '_list_typ'}>{this.listTypes[row.list_typ]}</span>,
                                    sortable: true
                                },
                                {
                                    title: 'von',
                                    key: 'erstellt_von',
                                    sortable: true
                                },
                                {
                                    title: 'Stichwort',
                                    key: 'bez',
                                    sortable: true
                                },
                                {
                                    title: 'Beschreibung',
                                    key: 'aufgabe',
                                    sortable: true
                                },
                                {
                                    title: 'bis wann',
                                    key: 'datum',
                                    sortable: true,
                                    cell: row =>
                                        <span key={row.id + '_datum'}>{row.datum !== undefined ? moment(row.datum.substring(0, 19)).format("DD.MM.YYYY") : ''} </span>
                                },
                                {
                                    title: 'Bemerkungen',
                                    key: 'bemerkungen',
                                    sortable: false,
                                    cell: row =>
                                        <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                            {this.renderBemerkungen(row.bemerkungen)}
                                        </div>
                                },
                                {
                                    title: 'erledigt',
                                    key: 'erledigt',
                                    sortable: true,
                                    cell: row =>
                                        <Form key={row.id + '_datum'}>
                                            <Form.Group controlId="closed" style={{ margin: 0 }} disabled={row.erledigt}>
                                                <Form.Check
                                                    checked={row.erledigt}
                                                    label="erledigt"
                                                    onChange={(e) => { this.setComplete(row) }}
                                                />
                                            </Form.Group>
                                        </Form>
                                },
                            ]}
                            data={data}
                            onSelect={(row) => {
                                this.setState({
                                    selectedTodo: _.cloneDeep(row)
                                })
                            }}
                        />
                    </Row>
                </Container>
            </div>
        )
    }

    print() {
        if (this.props.thisRoute !== this.props.selectedRoute) {
            return
        }
        if (this.state.printing !== true) {
            let printData = {
                sort: { [this.table.current.state.sortedCollumn]: this.table.current.state.sortDirection },
                erledigt: this.state.includeClosed,
                offen: this.state.includeOpen,
                va: this.state.selectedVA === 0 ? undefined : this.state.selectedVA
            }

            this.props.dispatch(setPrintTrigger(false))
            this.setState({
                printing: true
            })
            BackendService.printTodos(printData).then(response => {
                this.setState({
                    printing: false
                })
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.triggerPrint !== prevProps.triggerPrint) {
            this.print();
        }
    }
}

const mapStateToProps = (state) => {
    return {
        triggerPrint: state.navigation.printView,
    }
}

export default connect(mapStateToProps)(Todo)