import React, { Component } from 'react';
import { connect } from 'react-redux';
import BackendService from './BackendService';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import _ from 'lodash';

class Help extends Component {

    constructor(...args) {
        super(...args);

        this.state = {
            edit: false,
            editor: undefined
        }

        this.refrech = this.refrech.bind(this);
        this.refrech();
    }

    refrech() {
        BackendService.getHelp().then((helpArray) => {
            this.setState({
                help: _.keyBy(helpArray, (entry) => entry.id)
            })
        })
    }

    save() {
        let data = this.state.help[this.props.selectedTab.helpID]
        data = {
            ...data,
            entryhtml: this.state.editor.getData()
        }
        BackendService.editHelp(data).then(() => {
            this.refrech();
            this.setState({
                edit: false
            })
        })
    }

    render() {
        let helpID = this.props.selectedTab.helpID;
        if (this.state.help === undefined) {
            return (<div />)
        }
        return (
            <Container>
                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1, alignItems: 'center' }}>
                            <span>{this.state.help[helpID].title}</span>
                            {(this.props.rights[15] && !this.state.edit) && <Button onClick={() => this.setState({ edit: true })}>Eintrag Bearbeiten</Button>}
                            {(this.props.rights[15] && this.state.edit) && <Button onClick={() => this.save()}>Eintrag Speichern</Button>}
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: '40px' }}>
                    <Col>
                        {this.state.edit &&
                            [<CKEditor
                                editor={ClassicEditor}
                                data={this.state.help[helpID].entryhtml}
                                config={{
                                    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo']
                                }}
                                onInit={editor => {
                                    this.setState({ editor: editor })
                                }}
                            />,
                            <Button onClick={() => this.setState({ edit: false })}>abbrechen</Button>]
                        }

                        {!this.state.edit &&
                            <span dangerouslySetInnerHTML={{ __html: this.state.help[helpID].entryhtml }} />
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rights: state.userData.rights,
        selectedTab: state.navigation.selectedTab,
    }
}

export default connect(mapStateToProps)(Help)