import React, { Component } from 'react';
import BackendService from '../BackendService.js';
import _ from 'lodash';

import { connect } from 'react-redux';
import { createNotification } from '../actions';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal'

import CustomTable from '../CustomTable/CustomTable';
import FormBenutzer from './FormBenutzer'
import Button from 'react-bootstrap/Button';

class ViewBenutzer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selectedUser: {},
            creatingNew: false
        }

        BackendService.getUsers().then(
            users => {
                users.map(user => user.key = user.benutzer_id)
                this.setState({
                    data: users
                })
            }
        )

        this.columns = [
            {
                title: 'Benutzer',
                key: 'nachname',
                cell: row => <span key={row.key}>{row.nachname + ', ' + row.vorname}</span>,
                sortable: true
            }
        ]

        this.onNew = this.onNew.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.userExists = this.userExists.bind(this);

    }

    handleChange = e => {
        let key = e.target.id;
        let value = e.target.value
        this.setState(prevState => ({
            selectedUser: {
                ...prevState.VA,
                [key]: value
            }
        }))
    };

    onNew(user) {
        user.key = user.benutzer_id
        let data = _.cloneDeep(this.state.data)
        data.push(user)
        this.setState({
            data,
            selectedUser: _.cloneDeep(user),
            creatingNew: false
        })
        this.props.dispatch(createNotification('Benutzer', 'Benutzer erfolgreich angelegt'))
    }

    onEdit(user) {
        user.key = user.benutzer_id
        let data = _.cloneDeep(this.state.data)
        data[_.findIndex(data, (u) => u.benutzer_id === user.benutzer_id)] = user
        this.setState({ data })
        this.setState({
            data,
            selectedUser: _.cloneDeep(user)
        })
        this.props.dispatch(createNotification('Benutzer', 'editieren erfolgreich'))
    }

    onDelete(userID) {
        let data = _.cloneDeep(this.state.data)
        data.splice(_.findIndex(data, (u) => u.benutzer_id === userID), 1)
        this.setState({ data })
        this.props.dispatch(createNotification('Benutzer', 'löschen erfolgreich'))
    }

    userExists(userName) {
        return _.find(this.state.data, user => user.benutzername === userName) !== undefined
    }

    render() {
        return (
            <div className={this.props.className}>
                <Container fluid>
                    <Row>
                        <Button onClick={() => { this.setState({ creatingNew: true }) }} disabled={this.props.rights[2] === undefined}>Neuer Benutzer</Button>
                    </Row>
                    <Row className="max-height-wrapper-2">
                        <Col xs={7} className="customRow">
                            <CustomTable
                                headInterval={15}
                                title="Veranstaltungen"
                                initialSortKey="nachname"
                                collumns={this.columns}
                                data={this.state.data}
                                pagination
                                onSelect={(row) => {
                                    this.setState({
                                        selectedUser: _.cloneDeep(row)
                                    })
                                }}
                            />
                        </Col>
                        <Col xs={5} className="customTable">
                            <FormBenutzer
                                selected={this.state.selectedUser}
                                onSubmit={this.onEdit}
                                onDelete={this.onDelete}
                            />
                        </Col>
                    </Row>
                </Container>
                <Modal
                    size="xl"
                    show={this.state.creatingNew}
                    centered
                    onHide={() => this.setState({ creatingNew: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Neuer Benutzer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormBenutzer
                            new
                            selected={{}}
                            onSubmit={this.onNew}
                            userExists={this.userExists}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rights: state.userData.rights,
    }
}

export default connect(mapStateToProps)(ViewBenutzer)