import React, {Component} from 'react';
import {connect} from 'react-redux';
import ViewEvents from './Views/Events/ViewEvents.js';
import styles from './Views.module.css'
import EventForm from './Views/Events/EventForm';
import TODO from './Views/Todo';
import EditEventMainView from './Views/Events/EditEventMainView.js';
import ViewBenutzer from './Views/ViewBenutzer.js';
import ViewHome from './Views/ViewHome.js';
import ViewWerbemittelBestand from './Views/Werbemittel/ViewWerbemittelBestand.js'
import ViewAusruestungBestand from './Views/Ausruestung/ViewAusruestungBestand.js'
import CheckmasterMain from './Views/Checkmaster/CheckmasterMain.js';
import BackendService from './BackendService.js';
import { setVaSelected } from './actions/index.js';

class MainView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            vaName: ''
        }
        this.getVA = this.getVA.bind(this)
        this.getVA()
    }

    getVA() {
        if (this.props.va !== undefined && this.props.va !== 0) {
            BackendService.getVeranstaltungen(this.props.va).then(response => {
                this.props.setVaSelected(response)
                this.setState({
                    vaName: response.bez
                })
            })
        }
    }

    render() {
        let View = <div className={styles.baseView}>Empty View</div>
        let Title = this.props.selectedTab;
        switch (this.props.selectedTab) {
            case this.props.routes.HOME:
                Title = 'Home'
                View = <ViewHome className={styles.baseView} />
                break;
            case this.props.routes.VERANSTALTUNGEN:
                Title = 'Veranstaltungen'
                View = <ViewEvents className={styles.baseView} />
                break;
            case this.props.routes.VERANSTALTUNGEN.subRoutes.GRUNDDATEN:
                Title = 'Veranstaltungen - Grunddaten/Zugriffsrechte'
                View = <EventForm className={styles.baseView} />
                break;
            case this.props.routes.VERANSTALTUNGEN.subRoutes.EDIT_BETEILIGTE:
            case this.props.routes.VERANSTALTUNGEN.subRoutes.EDIT_PRE:
            case this.props.routes.VERANSTALTUNGEN.subRoutes.EDIT_DURING:
            case this.props.routes.VERANSTALTUNGEN.subRoutes.EDIT_POST:
            case this.props.routes.VERANSTALTUNGEN.subRoutes.EDIT_WERBEMITTEL:
            case this.props.routes.VERANSTALTUNGEN.subRoutes.EDIT_AUSRUESTUNG:
                // Title = 'Veranstaltung: ' + this.props.va.bez
                Title = 'Veranstaltung: ' + this.state.vaName
                View = <EditEventMainView className={styles.baseView} />
                break;
            case this.props.routes.BENUTZER:
                Title = 'Benutzer'
                View = <ViewBenutzer className={styles.baseView} />
                break;
            case this.props.routes.CHECKLISTEN:
            case this.props.routes.CHECKLISTEN.subRoutes.M_EDIT_PRE:
            case this.props.routes.CHECKLISTEN.subRoutes.M_EDIT_DURING:
            case this.props.routes.CHECKLISTEN.subRoutes.M_EDIT_POST:
                Title = 'Checklisten'
                View = <CheckmasterMain className={styles.baseView} />
                break;
            case this.props.routes.TODO:
                Title = 'ToDos'
                View = <TODO className={styles.baseView} />
                break;
            case this.props.routes.WERBEMITTEL:
                Title = 'Werbemittel/Präsente/Material'
                View = <ViewWerbemittelBestand id={0} className={styles.baseView} />
                break;
            case this.props.routes.AUSRUESTUNG:
                Title = 'Betreuerausrüstung verwalten'
                View = <ViewAusruestungBestand id={0} className={styles.baseView} />
                break;
            default:
                break;
        }
        return (
            <div className={styles.container}>
                <span className={styles.containerHeadline}>{Title}</span>
                {View}
            </div>
        )
    }

    componentDidUpdate(prevProps) {
        if (this.props.va !== prevProps.va) {
            this.getVA()
        }
    }
}

const mapStateToProps = (state) => {
    return {
        routes: state.navigation.routes,
        selectedTab: state.navigation.selectedTab,
        va: state.vaView.selectedVA
    }
}

const mapDispatchToProps = dispatch => {
    return {
      setVaSelected: (va) => dispatch(setVaSelected(va)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainView)
