import React, {Component} from 'react';
import moment from 'moment'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DatePicker from '../../../DatePicker'

import {ability} from '../../../config/ability'
import Can from '../../../config/Can'


class ChecklistFormDuring extends Component {

    constructor(props) {
        super(props)

        this.dateFromId = this.props.new ? 'newDatePicker_date_from' : 'datePicker_date_from'
        this.dateTillId = this.props.new ? 'newDatePicker_date_till' : 'datePicker_date_till'

        this.checkDatesInvalid = this.checkDatesInvalid.bind(this)
    }

    onSunday = () => {
        let dateStringFrom = this.props.selectedElement.datum != null ? this.props.selectedElement.datum.substring(0, 19) : ""
        let dateStringUntil = this.props.selectedElement.datum_bis != null ? this.props.selectedElement.datum_bis.substring(0, 19) : ""
        if (dateStringFrom === "" || dateStringUntil === "") {
            return false
        }

        let dateFrom = new Date(dateStringFrom)
        let dateUntil = new Date(dateStringUntil)

        return dateFrom.getDay() == 0 || dateUntil.getDay() == 0
    }

    checkDatesInvalid() {
        let dateStringFrom = this.props.selectedElement.datum != null ? this.props.selectedElement.datum.substring(0, 19) : ""
        let dateStringUntil = this.props.selectedElement.datum_bis != null ? this.props.selectedElement.datum_bis.substring(0, 19) : ""
        if (dateStringFrom === "" || dateStringUntil === "") {
            return false
        }

        let dateFrom = new Date(dateStringFrom)
        let dateUntil = new Date(dateStringUntil)
        if (dateUntil < dateFrom) {
            return true
        }
        return false
    }

    render() {
        let disableForm = this.props.disabled
        let blockEdits = !this.props.new
        blockEdits = blockEdits && !ability.can('edit', 'VA.Forms')

        if (this.props.selectedElement === undefined) {
            return (
                <Container fluid></Container>
            )
        }

        let ele = this.props.selectedElement

        let showHint = this.onSunday() || (ele.holidays && (ele.holidays.dateUntil.isHoliday || ele.holidays.dateFrom.isHoliday))

        return (
            <Container fluid>
                <Form onSubmit={e => this.props.handleSubmit(e)}>
                    {!this.props.new && [
                        <Form.Row key='creator'>
                            <Form.Group controlId="erstellt_von">
                                <Form.Label column>
                                    Ersteller: {' ' + this.props.selectedElement.erstellt_von}
                                </Form.Label>
                            </Form.Group>
                        </Form.Row>,
                        <Form.Row key='line2'>
                            <Col>
                                <Form.Group controlId="aktiv">
                                    <Form.Check checked={this.props.selectedElement.aktiv} label="aktiv" onChange={this.props.handleChangeCheckbox}
                                        disabled={disableForm || blockEdits} />
                                </Form.Group>
                            </Col>
                            {this.props.reminder !== undefined &&
                                <Button onClick={this.props.removeReminder}>
                                    <FontAwesomeIcon icon="exclamation" color="yellow" />{' gelesen'}
                                </Button>
                            }
                        </Form.Row>]}
                    <Form.Row>
                        <Col>
                            <Form.Group controlId='bez'>
                                <Form.Label>Stichwort</Form.Label>
                                <Form.Control type="text" as="textarea" rows={3} value={this.props.selectedElement.bez} onChange={this.props.handleChange}
                                    disabled={disableForm || (blockEdits && this.props.origElement.bez !== '')} />
                            </Form.Group>
                            <Form.Group controlId='ort'>
                                <Form.Label>Ort</Form.Label>
                                <Form.Control type="text" value={this.props.selectedElement.ort} onChange={this.props.handleChange}
                                    disabled={disableForm || (blockEdits && this.props.origElement.ort !== '')} />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col sm={6}>
                            <Form.Group controlId={this.props.new ? "datum-neu" :"datum"}>
                                <Form.Label>von:</Form.Label>
                                <Form.Control
                                    as={DatePicker}
                                    type="date"
                                    value={this.props.selectedElement.datum != null ? new Date(this.props.selectedElement.datum) : ""}
                                    changeValue={this.props.handleChange}
                                    disabled={disableForm || (blockEdits && this.props.origElement.datum !== '' && this.props.origElement.datum !== null)}
                                    controlId={this.dateFromId}
                                    valueId="datum"
                                    locale="de"
                                    dateFormat="DD.MM.YYYY"
                                    timeFormat="HH:mm"
                                />
                                <Form.Control.Feedback type="invalid" className={(ele.holidays && ele.holidays.dateFrom.isHoliday) ? 'd-block' : ''}>
                                    <p>{ele.holidays && ele.holidays.dateFrom.description}</p>
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId={this.props.new ? "datum_bis-neu" :"datum_bis"}>
                                <Form.Label>bis:</Form.Label>
                                <Form.Control
                                    as={DatePicker}
                                    type="date"
                                    value={this.props.selectedElement.datum_bis != null ? new Date(this.props.selectedElement.datum_bis) : ""}
                                    changeValue={this.props.handleChange}
                                    disabled={disableForm || (blockEdits && this.props.origElement.datum_bis !== '' && this.props.origElement.datum_bis !== null)}
                                    invalid={this.checkDatesInvalid()}
                                    controlId={this.dateTillId}
                                    valueId="datum_bis"
                                    locale="de"
                                    dateFormat="DD.MM.YYYY"
                                    timeFormat="HH:mm"
                                />
                                <Form.Control.Feedback type="invalid" className={this.checkDatesInvalid() ? 'd-block' : ''}>
                                    das Enddatum muss nach dem Startdatum liegen
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid" className={(ele.holidays && ele.holidays.dateUntil.isHoliday) ? 'd-block' : ''}>
                                    <p>{ele.holidays && ele.holidays.dateUntil.description}</p>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId='ist_aufgabe'>
                                <Form.Label>Typ:</Form.Label>
                                <Form.Control as="select" value={this.props.selectedElement.ist_aufgabe === undefined ? false : this.props.selectedElement.ist_aufgabe}
                                    onChange={this.props.handleChange}
                                    disabled={blockEdits}>
                                    <option key={'ist_aufgabe_true'} value={true}>Aufgabe</option>
                                    <option key={'ist_aufgabe_false'} value={false}>Programmpunkt</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId='zustaendig.benutzer_id'>
                                <Form.Label>zuständig:</Form.Label>
                                <Form.Control as="select" value={this.props.selectedElement.zustaendig !== undefined ? this.props.selectedElement.zustaendig.benutzer_id : ''}
                                    onChange={this.props.handleChange}
                                    disabled={disableForm || (blockEdits && this.props.origElement.zustaendig !== undefined)}>
                                    {this.props.userOptions}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Col>
                        <Form.Row>
                            <Form.Control.Feedback type="invalid" className={showHint ? 'd-block' : ''}>
                                <p>Keine Aufgabenzuordnung an SBC2-Mitarbeiter. Diese müssen von externen Dienstleistern (Eventagentur) erledigt werden.</p>
                            </Form.Control.Feedback>
                        </Form.Row>
                    </Col>
                    <Form.Row>
                        <Col>
                            <Form.Group controlId="aufgabe">
                                <Form.Label>Beschreibung:</Form.Label>
                                <Form.Control type="text" as="textarea" value={this.props.selectedElement.aufgabe} onChange={this.props.handleChange} rows="3"
                                    disabled={disableForm || (blockEdits && this.props.origElement.aufgabe !== '')} />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Can I="create" a="VA.Durchführung">
                        <Form.Row>
                            <Form.Group as={Row}>
                                <Col>
                                    {!this.props.new && <Button disabled={this.props.selectedElement.zu_element !== undefined || disableForm} onClick={(e) => this.props.handleNewSubElement(this.props.selectedElement.id)}>Unteraufgabe Hinzufügen</Button>}
                                    <Button disabled={disableForm || this.checkDatesInvalid()} type="submit">Speichern</Button>
                                </Col>
                            </Form.Group>
                        </Form.Row>
                    </Can>
                </Form>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        va: state.vaView.selectedVA,
        user: state.userData.user,
        rights: state.userData.rights,
        roles: state.userData.roles,
    }
}

export default connect(mapStateToProps)(ChecklistFormDuring)
