import React, {Component} from 'react'
import BackendService from '../../BackendService.js'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import CustomTable from '../../CustomTable/CustomTable'
import Spinner from 'react-bootstrap/Spinner'

import EventForm from './EventForm'
import Modal from 'react-bootstrap/Modal'

import {connect} from 'react-redux'
import {setVaSelected, setNavigationRoute} from '../../actions'
import moment from 'moment'
import _ from 'lodash'
import EventCopyForm from './EventCopyForm.js'

import Can from '../../config/Can'
import {ability} from '../../config/ability'

class ViewEvents extends Component {
  constructor() {
    super()

    this.onNewCallback = this.onNewCallback.bind(this)

    this.state = {
      data: [],
      showActive: true,
      showInactive: false,
      selectedType: 0,
      types: [],
      loading: true,
    }
    Promise.all([
      BackendService.getVeranstaltungen().then(VAs => {
        VAs.forEach(VA => {
          VA.art_bez = VA.art.bez
          VA.key = VA.id
          VA.leiter = VA.leitung !== undefined ? VA.leitung.kuerzel : ''
        })
        this.setState({
          data: VAs,
        })
      }),
      BackendService.getVATypen().then(typen => {
        let activeTypes = []
        typen.forEach(type => {
          if (type.aktiv) {
            activeTypes.push(type)
          }
        })
        this.setState({
          types: activeTypes,
          selectedType: activeTypes[0].id + '',
        })
      }),
    ]).then(() => {
      this.setState({
        loading: false,
      })
    })

    this.columns = [
      {
        title: 'Name',
        key: 'bez',
        sortable: true,
      },
      {
        title: 'Art',
        key: 'art_bez',
        sortable: true,
      },
      {
        title: 'Ort',
        key: 'ort',
        sortable: true,
      },
      {
        title: 'von',
        key: 'von',
        cell: row => (
          <span key={row.id + 'von'}>
            {row.von != null
              ? moment(row.von.substring(0, 10)).format('DD.MM.YYYY')
              : ''}
          </span>
        ),
        sortable: true,
      },
      {
        title: 'bis',
        key: 'bis',
        sort: 'asc',
        cell: row => (
          <span key={row.id + 'bis'}>
            {row.bis != null
              ? moment(row.bis.substring(0, 10)).format('DD.MM.YYYY')
              : ''}
          </span>
        ),
        sortable: true,
      },
      {
        title: 'PaxKunden',
        key: 'anz_teilnehmer',
        sortable: true,
      },
      {
        title: 'PaxBud',
        key: 'anz_teilnehmer_vertrieb',
        sortable: true,
      },
      {
        title: 'SBC2+Q',
        key: 'anz_teilnehmer_mkt3',
        sort: 'asc',
        sortable: true,
      },
      {
        title: 'Projektleitung intern',
        key: 'leiter',
        sortable: true,
      },
      {
        title: ability.can('edit', 'VA') ? 'Bearbeiten' : 'Ansehen',
        key: 'id',
        sortable: false,
        center: true,
        cell: row => (
          <div
            style={{display: 'flex', justifyContent: 'space-around'}}
            key={row.id + '_Buttons'}
          >
            <Button
              style={{marginRight: "5px"}}
              onClick={() => {
                this.props.dispatch(setVaSelected(row))
                this.props.dispatch(
                  setNavigationRoute(
                    this.props.routes.subRoutes.EDIT_BETEILIGTE,
                    row.id,
                  ),
                )
              }}
            >
              {ability.can('edit', 'VA') ? 'Bearbeiten' : 'Ansehen'}
            </Button>
            <Can I="edit" a="VA">
              {() => (
                <Button
                  onClick={() => {
                    this.props.dispatch(setVaSelected(row))
                    this.props.dispatch(
                      setNavigationRoute(
                        this.props.routes.subRoutes.GRUNDDATEN,
                        row.id,
                      ),
                    )
                  }}
                >
                  Grunddaten/Zugriffsrechte
                </Button>
              )}
            </Can>
          </div>
        ),
      },
    ]
  }

  onNewCallback(va) {
    let vas = _.cloneDeep(this.state.data)
    va.art_bez = va.art.bez
    va.key = va.id
    va.leiter = va.leitung !== undefined ? va.leitung.kuerzel : ''
    vas.push(va)
    this.setState({
      creatingNew: false,
      copy: false,
      data: vas,
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <div className={this.props.className}>
          <Row>
            <Col xs='auto'>
              <Spinner animation="border" />
            </Col>
            <Col xs='auto'>
              Veranstaltungen werden geladen...
            </Col>
          </Row>
        </div>
      )
    }

    let typeOptions = []
    this.state.types.forEach(type => {
      typeOptions.push(
        <option key={type.id} value={type.id}>
          {type.bez}
        </option>,
      )
    })

    let filteredData = this.state.data
    if (!this.state.showActive && !this.state.showInactive) {
      filteredData = []
    } else if (this.state.showActive !== this.state.showInactive) {
      filteredData = _.filter(filteredData, entry => {
        return entry.aktiv === this.state.showActive
      })
    }
    filteredData = _.filter(filteredData, entry => {
      return entry.typ.id + '' === this.state.selectedType
    })
    const css = `
      #mainContainer::-webkit-scrollbar {
        display: none;
      }
      
      /* Hide scrollbar for IE and Edge */
      #mainContainer {
        -ms-overflow-style: none;
      }
    `
    return (
      <div
        id="mainContainer"
        className={this.props.className}
        style={{height: '75vh', overflowY: 'auto'}}
      >
      <style>
          {css}
      </style>
        <Form>
          <Form.Row>
            <Col xs="auto" style={{display: 'flex', alignItems: 'flex-end'}}>
              <Form.Group
                as={Row}
                controlId="vaType"
                style={{alignItems: 'baseline'}}
              >
                <Form.Label>Veranstaltungstyp:</Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    value={this.state.selectedType}
                    onChange={e => {
                      this.setState({selectedType: e.target.value})
                    }}
                  >
                    {typeOptions}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
            <Col xs="auto" style={{display: 'flex', alignItems: 'center'}}>
              <Form.Group
                controlId="showActive"
                style={{alignItems: 'baseline'}}
              >
                <Form.Check
                  type="checkbox"
                  label="aktive Veranstaltungen zeigen"
                  checked={this.state.showActive}
                  onChange={e => {
                    this.setState({
                      showActive: e.target.checked === true ? true : false,
                    })
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs="auto" style={{margin: "10px", display: 'flex', alignItems: 'center'}}>
              <Form.Group
                controlId="showInactive"
                style={{alignItems: 'baseline'}}
              >
                <Form.Check
                  type="checkbox"
                  label="inaktive Veranstaltungen zeigen"
                  checked={this.state.showInactive}
                  onChange={e => {
                    this.setState({
                      showInactive: e.target.checked === true ? true : false,
                    })
                  }}
                />
              </Form.Group>
            </Col>
            <Can I="edit" a="VA">
              <Col xs="auto">
                <Button
                  onClick={() => {
                    this.setState({creatingNew: true})
                  }}
                >
                  + Neue Veranstaltungen
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  onClick={() => {
                    this.setState({copy: true})
                  }}
                  disabled={this.state.selectedVA === undefined}
                >
                  Ausgewählte Veranstaltungen kopieren
                </Button>
              </Col>
            </Can>
          </Form.Row>
        </Form>
        <CustomTable
          initialSortKey={'von'}
          collumns={this.columns}
          data={filteredData}
          onSelect={row => {
            this.setState({
              selectedVA: row,
            })
          }}
          onDoubleClick={row => {
            this.props.dispatch(setVaSelected(row))
            this.props.dispatch(
              setNavigationRoute(
                this.props.routes.subRoutes.EDIT_BETEILIGTE,
                row.id,
              ),
            )
          }}
        />
        <Modal
          size="xl"
          show={this.state.creatingNew}
          centered
          onHide={() => this.setState({creatingNew: false})}
        >
          <Modal.Body>
            <EventForm new onNewCallback={this.onNewCallback} />
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.copy}
          centered
          onHide={() => this.setState({copy: false})}
        >
          <Modal.Body>
            <EventCopyForm
              VA={this.state.selectedVA}
              succsessCB={this.onNewCallback}
              onCancel={() => this.setState({copy: false})}
            />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedRoute: state.navigation.selectedTab,
    routes: state.navigation.routes.VERANSTALTUNGEN,
    rights: state.userData.rights,
  }
}

export default connect(mapStateToProps)(ViewEvents)
