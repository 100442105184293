import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';


class CheckmasterForm extends Component {

    render() {
        let disableForm = this.props.disabled
        if (this.props.selectedElement === undefined) {
            return (
                <Container fluid></Container>
            )
        }
        return (
            <Container fluid>
                <Form onSubmit={e => this.props.handleSubmit(e)}>
                    <Form.Row>
                        <Col>
                            <Form.Group as={Row} controlId="aktiv">
                                <Col sm={{ span: 10 }}>
                                    <Form.Check checked={this.props.selectedElement.aktiv} label="aktiv" onChange={this.props.handleChangeCheckbox} disabled={disableForm} />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Group controlId='bez'>
                                <Form.Label>Stichwort</Form.Label>
                                <Form.Control type="text" value={this.props.selectedElement.bez} onChange={this.props.handleChange} disabled={disableForm} />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    {this.props.during && <Form.Row>
                        <Col sm={6}>
                            <Form.Group controlId='programmpunkt'>
                                <Form.Label>Typ:</Form.Label>
                                <Form.Control as="select" value={this.props.selectedElement.programmpunkt === undefined ? false : this.props.selectedElement.programmpunkt}
                                    onChange={this.props.handleChange}
                                    disabled={disableForm}>
                                    <option key={'ist_aufgabe_true'} value={true}>Aufgabe</option>
                                    <option key={'ist_aufgabe_false'} value={false}>Programmpunkt</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Form.Row>}
                    <Form.Row>
                        <Col>
                            <Form.Group controlId="aufgabe">
                                <Form.Label>Beschreibung:</Form.Label>
                                <Form.Control as="textarea" value={this.props.selectedElement.aufgabe} onChange={this.props.handleChange} rows="6" disabled={disableForm} />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Group controlId='sort'>
                                <Form.Label>Sortierung</Form.Label>
                                <Form.Control type="number" value={this.props.selectedElement.sort===undefined ? 0 : this.props.selectedElement.sort} onChange={this.props.handleChange} disabled={disableForm} />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        {!this.props.new && <Col>
                            <Button disabled={disableForm} onClick={()=>this.props.onDelete(this.props.selectedElement.id)}>Löschen</Button>
                        </Col>}
                        <Col>
                            <Button disabled={disableForm} type="submit">Speichern</Button>
                        </Col>
                    </Form.Row>
                </Form>
            </Container>
        )
    }
}

export default CheckmasterForm
