import { CREATE_NOTIFICATION, REMOVE_NOTIFICATION, DELETE_NOTIFICATION } from '../actions/actionTypes'
import _ from 'lodash'

const initialState = {
    index: 0,
    messages: []
}

const vaView = (state = initialState, action) => {
    let prevMessages = _.cloneDeep(state.messages)
    switch (action.type) {
        case CREATE_NOTIFICATION:
            let newNotification = {
                ...action.value,
                show: true
            }
            prevMessages.push(newNotification)
            return Object.assign({}, state, {
                messages: prevMessages,
                index: state.index+1
            })
        case REMOVE_NOTIFICATION:
            let index = _.findIndex(prevMessages, msg => msg.id === action.value.id)
            if (index===-1) {
                return state;
            }
            prevMessages[index]={
                ...prevMessages[index],
                show: false
            }
            return Object.assign({}, state, {
                messages: prevMessages
            })
        case DELETE_NOTIFICATION:
            let delIndex = _.findIndex(prevMessages, msg => msg.id === action.value.id)
            if (delIndex===-1) {
                return state;
            }
            prevMessages.splice(delIndex, 1)
            return Object.assign({}, state, {
                messages: prevMessages
            })
        default:
            return state
    }
}

export default vaView